import { type GetTranslationStringCb } from "@app/contexts";
import { AccessLevel } from "@app/types";
import { type LibraryCollectionDto } from "@shared/api-client";
import { type JsonUISchema, type JsonSchema } from "@shared/form-builder";

interface SchemaContext {
  ts: GetTranslationStringCb;
  collections: LibraryCollectionDto[];
  categories: string[];
}

interface UISchemaOptions {
  hideCollection?: boolean;
}

export const getLibraryItemSchema = ({ ts, collections }: SchemaContext): JsonSchema => ({
  type: "object",
  properties: {
    collection: {
      type: "string",
      title: ts("fields.library.collection"),
      oneOf:
        collections && collections.length > 0
          ? collections.map((collection) => ({
              title: collection.name,
              const: collection.uuid
            }))
          : undefined,
      default: collections?.[0]?.uuid,
      readOnly: !collections
    },
    thumbnail: {
      type: "string",
      title: ts("fields.library.thumbnail"),
      format: "data-url"
    },
    name: {
      type: "string",
      title: ts("fields.library.name")
    },
    description: {
      type: "string",
      title: ts("fields.library.description")
    },
    category: {
      type: "string",
      title: ts("fields.library.category")
    },
    accessLevel: {
      type: "string",
      title: ts("fields.library.accessLevel"),
      oneOf: Object.values(AccessLevel).map((value) => ({
        title: ts(`fields.accessLevel.${value}`),
        const: value
      })),
      default: "public"
    }
  },
  required: ["name", "description", "thumbnail", "collection", "accessLevel"]
});

export const getLibraryItemUISchema = ({ categories }: SchemaContext, options?: UISchemaOptions): JsonUISchema => {
  const leftFields: JsonUISchema[] = [
    {
      type: "Control",
      scope: "#/properties/thumbnail"
    },
    {
      type: "Control",
      scope: "#/properties/accessLevel"
    }
  ];

  const rightFields: JsonUISchema[] = [
    {
      type: "Control",
      scope: "#/properties/category",
      options: {
        autocomplete: categories.filter(Boolean)
      }
    },
    {
      type: "Control",
      scope: "#/properties/name"
    },
    {
      type: "Control",
      scope: "#/properties/description",
      options: {
        multi: true
      }
    }
  ];

  if (!options?.hideCollection) {
    rightFields.unshift({
      type: "Control",
      scope: "#/properties/collection"
    });
  }

  return {
    type: "HorizontalLayout",
    elements: [
      {
        type: "VerticalLayout",
        elements: leftFields
      },
      {
        type: "VerticalLayout",
        elements: rightFields
      }
    ]
  };
};
