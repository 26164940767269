import { Outlet } from "react-router-dom";
import { LibraryContextProvider } from "@app/library";

export default function MyLibraryLayout() {
  return (
    <>
      <LibraryContextProvider>
        <Outlet />
      </LibraryContextProvider>
    </>
  );
}
