import { type ReactNode, useState, useContext, useEffect, useMemo } from "react";
import { Box, CircularProgress, Collapse } from "@mui/material";
import { useDrop } from "react-dnd";

import { type GamePluginDto } from "@shared/api-client";
import { ComponentContext } from "@shared/game-player";
import { type Asset } from "@shared/game-engine";
import { GamePluginType, type PluginManifest } from "@shared/utils/plugins";

import { SectionTitle } from "@app/components";
import { ComponentTrash, ComponentUpload, useEditor } from "@app/editor";
import { mergeDeep } from "@app/utils";
import type { EditorDropItem } from "@app/types";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { ComponentPickerList } from "./ComponentPickerList";

import { extendTemplatesFromAssets } from "./utils";
import type { ComponentPickerItem } from "./types";

interface Props {
  title: ReactNode;
  component: GamePluginDto;
  onUpload?: (files: File[]) => void;
  onSelect?: (id: string, data: ComponentPickerItem) => void;
  onDelete?: (asset: Asset, mustConfirm?: boolean) => void;
  onExpand?: (expanded: boolean) => void;
  expanded?: boolean;
  hideExpand?: boolean;
}

export function ComponentPickerGroup(props: Props) {
  const { title, component, onSelect, onUpload, onDelete, expanded, onExpand } = props;
  const [manifest, setManifest] = useState<PluginManifest<Record<string, unknown>> | null>();
  const [items, setItems] = useState<ComponentPickerItem[] | null>(null);
  const [dragging, setDragging] = useState(false);
  const { gameAssets, gameDataInfo } = useEditor();

  const {
    plugins: { getPluginManifest }
  } = useContext(ComponentContext);

  // Drop component (trash)
  const [, drop] = useDrop<EditorDropItem>(() => ({
    accept: GamePluginType.GAME_COMPONENT,
    drop: (item, monitor) => {
      if (item.asset && monitor.isOver({ shallow: true })) {
        // Delete the item
        onDelete?.(item.asset, item.inUse);
      }
    }
  }));

  useEffect(() => {
    if (expanded) {
      getPluginManifest(component.uuid, component.version.toString()).then(setManifest);
    }
  }, [component.builtIn, component.uuid, component.version, expanded, getPluginManifest]);

  // Build the item list. Load manifest and assets
  useEffect(() => {
    if (manifest) {
      const templates = manifest?.templates || [];
      const templateDefaults = manifest?.templateDefaults || {};

      const items: ComponentPickerItem[] = templates.map((template) => ({
        name: template.name || component.name || manifest.name,
        thumbnail: template.thumbnail || manifest.thumbnail || component.thumbnail,
        component,
        manifest,
        template: mergeDeep(templateDefaults, template)
      }));

      // Append assets as templates
      if (gameAssets) {
        items.push(...extendTemplatesFromAssets(component, manifest, gameAssets, gameDataInfo?.assets));
      }

      setItems(items);
    }
  }, [component, gameAssets, gameDataInfo?.assets, manifest]);

  const sectionTitleActions = useMemo(() => {
    return onExpand
      ? [
          {
            key: "collapse",
            icon: !expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />
          }
        ]
      : [];
  }, [expanded, onExpand]);

  return (
    <>
      <SectionTitle
        title={title}
        actions={sectionTitleActions}
        onClick={onExpand ? () => onExpand?.(!expanded) : undefined}
      />
      <Collapse in={expanded} sx={{ overflow: "auto" }}>
        {manifest?.features.allowUpload && !dragging && (
          <ComponentUpload accept={manifest.features.allowUpload} onDrop={onUpload} />
        )}
        {manifest?.features.allowUpload && dragging && <ComponentTrash ref={drop} />}
        {items && manifest ? (
          <ComponentPickerList
            items={items}
            onSelect={onSelect}
            onDragStart={() => setDragging(true)}
            onDragEnd={() => setDragging(false)}
          />
        ) : null}
        {!items || !manifest ? (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : null}
      </Collapse>
    </>
  );
}
