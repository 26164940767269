import { useCallback } from "react";
import { Grid } from "@mui/material";

import { type GameDefinitionDto } from "@shared/api-client";

import { BlankCard, TemplateCard } from "@app/components";
import { useTranslation } from "@app/hooks";

import BlankProjectImage from "@app/assets/blank-project.jpg";
import { GameStatus } from "@app/types";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

interface Props {
  templates: GameDefinitionDto[];
  onSelect?: (template?: GameDefinitionDto) => void;
}

export function TemplateItem({ template, onClick }: { template: GameDefinitionDto; onClick: () => void }) {
  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <TemplateCard template={template} onClick={onClick} />
    </Grid>
  );
}

export function TemplateList({ templates, onSelect }: Props) {
  const { t, ts } = useTranslation();

  const handleCreateBlank = useCallback(() => {
    onSelect?.({
      name: t("create.blankTemplate").toString(),
      description: t("create.blankTemplateDescription").toString(),
      bgImage: BlankProjectImage,
      category: "",
      uuid: "",
      createdAt: new Date(),
      updatedAt: new Date(),
      version: 0,
      currentVersion: 0,
      lastVersion: 0,
      status: GameStatus.PUBLISHED,
      accessLevel: "public"
    });
  }, [onSelect, t]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <BlankCard
            title={ts("create.createGame")}
            subtitle={ts("create.blankTemplate")}
            Icon={AddCircleOutlineOutlinedIcon}
            onClick={handleCreateBlank}
          />
        </Grid>
        {templates.map((template) => (
          <TemplateItem key={template.uuid} template={template} onClick={() => onSelect?.(template)} />
        ))}
      </Grid>
    </>
  );
}
