import { useCallback, useEffect, useState } from "react";
import { Box, Paper, useTheme } from "@mui/material";

import { type LibraryCollectionDto } from "@shared/api-client";
import { Form } from "@shared/form-builder";

import type { AccessLevel } from "@app/types";
import { useTranslation } from "@app/hooks";
import { Button, PageLoading, SectionTitle } from "@app/components";
import { useLibrary } from "@app/library";
import { getLibraryCollectionSchema, getLibraryCollectionUISchema } from "@app/schemas/library.collection.schema";

export default function LibraryCollectionSettingsPage() {
  const { t, ts } = useTranslation();
  const { selectedCollection, updateSelectedCollection, doRefresh } = useLibrary();
  const [isDirty, setIsDirty] = useState(false);
  const [currentData, setCurrentData] = useState<LibraryCollectionDto | undefined>(selectedCollection);
  const theme = useTheme();

  useEffect(() => {
    setCurrentData(selectedCollection);
  }, [selectedCollection]);

  const discard = useCallback(() => {
    setCurrentData(selectedCollection);
    setIsDirty(false);
  }, [selectedCollection]);

  const submit = useCallback(() => {
    if (currentData) {
      const { name, description, accessLevel, thumbnail } = currentData;

      updateSelectedCollection({ name, description, accessLevel: accessLevel as AccessLevel, thumbnail });
      setIsDirty(false);

      // Do reload
      doRefresh();
    }
  }, [currentData, doRefresh, updateSelectedCollection]);

  const handleUpdate = useCallback((data: LibraryCollectionDto) => {
    setCurrentData(data);
    setIsDirty(true);
  }, []);

  if (!currentData) {
    return <PageLoading />;
  }

  return (
    <Paper sx={{ padding: theme.spacing(8) }} elevation={0}>
      <SectionTitle title={t("library.settings.title")} />
      <p>{t("library.settings.description")}</p>

      <Form
        schema={getLibraryCollectionSchema({ ts }, { restrictAccessLevel: !currentData.ownerId })}
        uiSchema={getLibraryCollectionUISchema()}
        data={currentData}
        onChange={handleUpdate}
      />

      <Box textAlign='center' width='100%' mt={4}>
        <Button onClick={discard} variant='contained' color='info' sx={{ mr: 1 }}>
          {t("common.discardChanges")}
        </Button>
        <Button onClick={submit} variant='contained' disabled={!isDirty}>
          {t("common.applyChanges")}
        </Button>
      </Box>
    </Paper>
  );
}
