import { useContext } from "react";
import { Box, styled } from "@mui/material";
import { useDrag } from "react-dnd";

import { GamePluginType, getAssetUrl } from "@shared/utils/plugins";
import { ComponentContext } from "@shared/game-player";

import type { EditorDropItem } from "@app/types";
import { Image } from "@app/components";
import { useEditor } from "@app/editor";

import QuestionMarkIcon from "@mui/icons-material/HelpOutline";

import type { ComponentPickerItem } from "./types";

interface Props {
  item: ComponentPickerItem;
  onSelect?: (id: string, data: ComponentPickerItem) => void;
  onDragStart?: (item: ComponentPickerItem) => void;
  onDragEnd?: () => void;
}

export const ComponentItemBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(4, 2),
  cursor: "pointer",
  borderRadius: theme.shape.borderRadius,

  transition: theme.transitions.create(["background-color", "transform"], {
    duration: theme.transitions.duration.shortest
  }),

  "&:hover": {
    backgroundColor: theme.palette.action.disabledBackground,

    ">.MuiCard-root:hover": {
      transform: "none"
    }
  },

  "&.in-use": {
    fontWeight: "bold",
    color: theme.palette.primary.main
  }
}));

const ComponentItemImage = styled(Image)({
  flex: "1 1 auto",
  width: "100%",
  height: "100%",
  objectFit: "contain"
});

const ComponentItemText = styled("div")(({ theme }) => ({
  flex: "0 0 auto",
  marginTop: theme.spacing(2),
  textAlign: "center",
  fontSize: "0.8rem",
  wordBreak: "break-word"
}));

export function ComponentPickerListItem({ item, onSelect, onDragStart, onDragEnd }: Props) {
  const { gameDefinition } = useEditor();
  const { pluginsBaseUrl, builtinPluginsBaseUrl } = useContext(ComponentContext);

  // Drag component
  const [, drag] = useDrag<EditorDropItem>(() => ({
    type: GamePluginType.GAME_COMPONENT,
    item: () => {
      onDragStart?.(item);

      if (!item.template) {
        return null;
      }

      const dropItem: EditorDropItem = {
        ...item.template,
        component: item.component.uuid,

        // Additional properties
        asset: item.asset,
        inUse: item.inUse
      };

      return dropItem;
    },
    end: onDragEnd
  }));

  // Calculate the thumbnail.
  const thumbnail = item.thumbnail || item.manifest?.thumbnail || item.component.thumbnail;
  const thumbnailUrl = getAssetUrl(thumbnail, item.template?.properties || {}, {
    assetBaseUrl: gameDefinition?.assetBaseUrl || "/assets",
    libraryBaseUrl: import.meta.env.VITE_LIBRARY_BASE_URL || "/library",
    pluginBaseUrl: `${item.component.builtIn ? builtinPluginsBaseUrl : pluginsBaseUrl}${item.component.basePath}`,
    version: item.component.version
  });

  return (
    <ComponentItemBox
      onClick={() => onSelect?.(item.component.uuid, item)}
      ref={drag}
      className={item.inUse ? "in-use" : ""}
    >
      {thumbnailUrl ? (
        <ComponentItemImage src={thumbnailUrl} alt={item.name} />
      ) : (
        <QuestionMarkIcon fontSize='large' sx={{ opacity: 0.3 }} />
      )}
      <ComponentItemText>{item.name}</ComponentItemText>
    </ComponentItemBox>
  );
}
