// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/auth/login`
  | `/auth/logout`
  | `/auth/password-recovery`
  | `/auth/password-recovery-success`
  | `/auth/password-reset`
  | `/auth/password-reset-success`
  | `/auth/register`
  | `/auth/register-success`
  | `/auth/verify-email`
  | `/dashboard`
  | `/dashboard/account`
  | `/dashboard/account/plan`
  | `/dashboard/account/profile`
  | `/dashboard/create`
  | `/dashboard/my-library`
  | `/dashboard/my-library/:uuid`
  | `/dashboard/my-library/:uuid/content`
  | `/dashboard/my-library/:uuid/settings`
  | `/dashboard/projects`
  | `/dashboard/projects/:uuid`
  | `/dashboard/projects/:uuid/v`
  | `/dashboard/projects/:uuid/v/:version`
  | `/dashboard/projects/:uuid/v/:version/editor`
  | `/dashboard/projects/:uuid/v/:version/editor/*`
  | `/dashboard/projects/:uuid/v/:version/editor/components`
  | `/dashboard/projects/:uuid/v/:version/editor/elements`
  | `/dashboard/projects/:uuid/v/:version/editor/library`
  | `/dashboard/projects/:uuid/v/:version/editor/locks`
  | `/dashboard/projects/:uuid/v/:version/editor/minigames`
  | `/dashboard/projects/:uuid/v/:version/editor/multimedia`
  | `/dashboard/projects/:uuid/v/:version/editor/text`
  | `/dashboard/projects/:uuid/v/:version/editor/ui`
  | `/dashboard/projects/:uuid/v/:version/settings`
  | `/dashboard/projects/:uuid/v/:version/settings/canvas`
  | `/dashboard/projects/:uuid/v/:version/settings/gamification`
  | `/dashboard/projects/:uuid/v/:version/settings/general`
  | `/dashboard/projects/:uuid/v/:version/settings/revisions`
  | `/dashboard/projects/:uuid/v/:version/tour`
  | `/dashboard/projects/:uuid/v/:version/tour/canvas`
  | `/dashboard/projects/:uuid/v/:version/tour/general`
  | `/offline`
  | `/preview/:uuid/:version`

export type Params = {
  '/dashboard/my-library/:uuid': { uuid: string }
  '/dashboard/my-library/:uuid/content': { uuid: string }
  '/dashboard/my-library/:uuid/settings': { uuid: string }
  '/dashboard/projects/:uuid': { uuid: string }
  '/dashboard/projects/:uuid/v': { uuid: string }
  '/dashboard/projects/:uuid/v/:version': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/editor': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/editor/*': { uuid: string; version: string; '*': string }
  '/dashboard/projects/:uuid/v/:version/editor/components': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/editor/elements': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/editor/library': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/editor/locks': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/editor/minigames': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/editor/multimedia': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/editor/text': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/editor/ui': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/settings': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/settings/canvas': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/settings/gamification': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/settings/general': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/settings/revisions': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/tour': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/tour/canvas': { uuid: string; version: string }
  '/dashboard/projects/:uuid/v/:version/tour/general': { uuid: string; version: string }
  '/preview/:uuid/:version': { uuid: string; version: string }
}

export type ModalPath = `/dashboard/projects/[uuid]/v/[version]/publish`

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
