// TODO: Move to shared library

export enum GameStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
  ARCHIVED = "archived"
}

export enum AccessLevel {
  PUBLIC = "public",
  PRIVATE = "private"
}
