import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useAppContext, useBackend, useShortcuts, useTagManager, useTranslation } from "@app/hooks";

// PSA: Do not put context providers here. Because Modals won't get them.
// Need to double check with generouted.
export default function AppLayout() {
  const { superPowers, enableSuperPowers, notify } = useAppContext();
  const { isAdmin } = useBackend();
  const { t } = useTranslation();
  const location = useLocation();
  const tm = useTagManager();

  // Listen keyboard shortcuts
  useShortcuts({
    "cmd+shift+e": () => isAdmin && enableSuperPowers(true)
  });

  // Notify user when super powers are enabled
  useEffect(() => {
    if (superPowers) {
      notify(t("common.superPowersEnabled"), "🦸‍♂️");
    }
  }, [superPowers, notify, t]);

  // Track page views
  useEffect(() => {
    tm("page_view", {
      page_location: document.location.protocol + "//" + document.location.host + location.pathname,
      page_title: document.title,
      page_path: location.pathname
    });
  }, [location.pathname, tm]);

  return (
    <main>
      <Outlet />
    </main>
  );
}
