import { type ReactNode, useRef, useState } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";

import { useBackend, useTranslation } from "@app/hooks";
import { useNavigate } from "@app/router";
import { DefaultAvatar } from "@app/components";
import { getAvatarMenuItems } from "@app/config";

interface MenuItem {
  key: string;
  icon?: ReactNode;
  label: ReactNode;
  action?: () => void;
}

export function AvatarMenu() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isBackendReady, loggedInUser } = useBackend();
  const { t } = useTranslation();
  const anchorEl = useRef(null);

  const [showMenu, setShowMenu] = useState(false);

  if (!isBackendReady || !loggedInUser) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{}}>
      <Tooltip title={t("header.profile")}>
        <IconButton onClick={() => setShowMenu(!showMenu)} ref={anchorEl} sx={{ p: 0 }}>
          {!loggedInUser?.avatar ? <DefaultAvatar /> : <Avatar alt='User' src={loggedInUser.avatar} />}
        </IconButton>
      </Tooltip>
      <Menu open={showMenu} onClose={() => setShowMenu(false)} keepMounted anchorEl={anchorEl.current}>
        <Typography variant='body2' component='li' sx={{ padding: theme.spacing(2, 2) }} textAlign='center'>
          {t("header.welcome", { username: loggedInUser.username })}
        </Typography>
        {getAvatarMenuItems().map(({ key, link, label, externalLink, icon, onClick }) => (
          <MenuItem
            key={key}
            onClick={(e) => {
              e.preventDefault();
              link && navigate(link);
              externalLink && window.open(externalLink, "_blank");
              onClick && onClick();
              setShowMenu(false);
            }}
          >
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            {label && <ListItemText>{t(label)}</ListItemText>}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
