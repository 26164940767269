import { useCallback, useEffect, useMemo, useState } from "react";
import domtoimage from "dom-to-image-more";
import { Alert, styled } from "@mui/material";

import { ModalSection, MultiSectionModal } from "@shared/form-builder/common/MultiSectionModal";
import { type Asset, findDeep, type GameElement } from "@shared/game-engine";
import { Form } from "@shared/form-builder";
import { type GameAssetDto } from "@shared/api-client";

import { Button, Image, PageLoading } from "@app/components";
import { useTranslation } from "@app/hooks";
import { getLibraryItemSchema, getLibraryItemUISchema } from "@app/schemas/library.item.schema";
import { useEditor } from "@app/editor/useEditor";

import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";

import { type CreateCollectionItemFormData } from "./types";
import { useLibrary } from "./useLibrary";

interface Props {
  elementData?: GameElement;
  assets?: GameAssetDto[];
  onSuccess?: () => void;
  onCancel?: () => void;
}

function extractAssets(elementData: GameElement): string[] {
  const assets = findDeep<string>(
    elementData,
    (object) => typeof object === "string" && !!object.match(/^(asset|library|plugin):\/\//g)
  );

  return assets;
}

const AssetList = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  marginTop: 16,

  img: {
    height: 100,
    maxWidth: 200,
    objectFit: "contain",
    border: "1px solid " + theme.palette.divider,
    borderRadius: 4
  }
}));

export function AddToLibraryModal(props: Props) {
  const { elementData, onCancel, onSuccess } = props;
  const { collections, addToCollection, getCategoriesByCollectionUuid } = useLibrary();
  const { renderAssetUrl, gameAssets } = useEditor();
  const { t, ts } = useTranslation();

  const [data, setData] = useState<CreateCollectionItemFormData>();
  const [categories, setCategories] = useState<string[]>([]);

  const handleChange = useCallback((newData: CreateCollectionItemFormData) => {
    setData(newData);
  }, []);

  const handleSubmit = useCallback(() => {
    if (data && data.collection) {
      const { collection, ...itemData } = data;

      addToCollection(collection, itemData).then(() => {
        onSuccess?.();
      });
    }
  }, [addToCollection, data, onSuccess]);

  useEffect(() => {
    if (data?.collection) {
      getCategoriesByCollectionUuid(data?.collection).then(setCategories);
    } else {
      setCategories([]);
    }
  }, [data?.collection, getCategoriesByCollectionUuid]);

  // Auto-select first collection
  useEffect(() => {
    if (data && !data.collection && collections) {
      data.collection = collections[0].uuid;
    }
  }, [collections, data]);

  useEffect(() => {
    if (!elementData) {
      setData(undefined);
      return;
    }

    const assets = extractAssets(elementData);
    const assetsMap: [Asset, string][] = [];

    if (gameAssets) {
      assets.forEach((assetPath) => {
        const asset = gameAssets.find((a) => a.path === assetPath);
        if (asset) {
          assetsMap.push([asset, renderAssetUrl(assetPath)]);
        } else {
          // TODO: Handle library or plugin assets, not present in gameAssets
          console.warn("Asset not found", assetPath);
        }
      });
    }

    const { component, stage, properties, translations, style, rawStyle } = elementData;
    const { width, height, originX, originY } = stage;

    const newData: CreateCollectionItemFormData = {
      component,
      name: elementData.name,
      description: "",
      configuration: {
        stage: {
          width,
          height,
          originX,
          originY
        },
        properties,
        translations,
        style,
        rawStyle
      },
      assets: assetsMap
    };

    // Generate thumbnail (async)
    const element = document.getElementById("el-" + elementData.id);

    if (element) {
      domtoimage
        .toPng(element, {
          style: {
            background: "transparent",
            outline: "none",
            // Remove any transformations, with extra scale to avoid clipping
            transform: `translate(0, 0) scale(.95) rotate(0deg)`,
            transformOrigin: ".5 .5"
          }
        })
        .then((thumbnail: string) => {
          newData.thumbnail = thumbnail;
          setData(newData);
        })
        .catch((err: unknown) => {
          console.error("Error generating thumbnail", err);
          setData(newData);
        });
    } else {
      // No thumbnail available
      setData(newData);
    }
  }, [elementData, gameAssets, renderAssetUrl]);

  const footer = useMemo(
    () => (
      <>
        <Button onClick={onCancel}>{t("common.cancel")}</Button>
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          {t("library.addToLibrary")}
        </Button>
      </>
    ),
    [handleSubmit, onCancel, t]
  );

  const schemaContext = useMemo(() => ({ t, ts, collections, categories }), [categories, collections, t, ts]);

  return (
    <MultiSectionModal open={!!elementData} onClose={onCancel} footer={footer}>
      <ModalSection id='main' title={t("library.addToLibrary")} icon={<AddToPhotosIcon />}>
        {!data && <PageLoading />}
        {data && (
          <Form
            schema={getLibraryItemSchema(schemaContext)}
            uiSchema={getLibraryItemUISchema(schemaContext)}
            data={data}
            onChange={handleChange}
          />
        )}
        {data?.assets?.length ? (
          <Alert severity='info'>
            {t("library.assetsToUpload")}
            <AssetList>{data?.assets.map(([asset, url]) => <Image key={asset.path} src={url} />)}</AssetList>
          </Alert>
        ) : null}
      </ModalSection>
    </MultiSectionModal>
  );
}
