import { AssetType } from "../consts";

export const ASSET_PROTOCOL = "asset://";
export const LIBRARY_PROTOCOL = "library://";
export const COLLECTION_PROTOCOL = "collection://";

export interface Asset {
  type: AssetType;
  path: string;

  name?: string;
  mimeType?: string;
  size?: number;
}
