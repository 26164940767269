import { type MenuItem } from "@app/types";

// import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
// import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
// import BrandingWatermarkOutlinedIcon from "@mui/icons-material/BrandingWatermarkOutlined";
// import ScoreboardOutlinedIcon from "@mui/icons-material/ScoreboardOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import GridViewIcon from "@mui/icons-material/GridView";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
// import InventoryIcon from "@mui/icons-material/Inventory";
// import HelpIcon from "@mui/icons-material/Help";
// import ColorLensIcon from "@mui/icons-material/ColorLens";
// import UpdateIcon from "@mui/icons-material/Update";
// import ExtensionIcon from "@mui/icons-material/Extension";
import MinigameIcon from "@mui/icons-material/SportsEsports";
// import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import TextIcon from "@mui/icons-material/TextFields";
import MultimediaIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import ShapesIcon from "@mui/icons-material/Interests";
// import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import GamePadIcon from "@mui/icons-material/Gamepad";
import SchoolIcon from "@mui/icons-material/School";
import StarIcon from "@mui/icons-material/Star";
import PrivacyIcon from "@mui/icons-material/PrivacyTip";
import CookieIcon from "@mui/icons-material/Cookie";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";

interface MenuSettings {
  superPowers?: boolean;
}

export const getAvatarMenuItems = (): MenuItem[] => [
  {
    key: "profile",
    icon: <AccountCircleOutlinedIcon />,
    label: "header.editProfile",
    link: "/dashboard/account/profile"
  },
  {
    key: "plans",
    icon: <StarIcon />,
    label: "header.plans",
    link: "/dashboard/account/plan"
  },
  {
    key: "privacy",
    icon: <PrivacyIcon />,
    label: "header.privacy",
    externalLink: "https://theufolab.com/politica-de-privacidad/"
  },
  {
    key: "cookies",
    icon: <CookieIcon />,
    label: "header.cookies",
    onClick: () => {
      try {
        window.Cookiebot?.show();
      } catch (_err) {
        console.error("Cookiebot not found");
      }
    }
  },
  {
    key: "logout",
    icon: <LogoutOutlinedIcon />,
    label: "header.logout",
    link: "/auth/logout"
  }
];

export const getDrawerMainMenuItems = (settings: MenuSettings = {}): MenuItem[] =>
  [
    // {
    //   key: "dashboard",
    //   icon: <DashboardOutlinedIcon />,
    //   label: "menu.dashboard",
    //   link: "/dashboard"
    // },
    {
      key: "create",
      icon: <CreateOutlinedIcon />,
      label: "menu.createProject",
      link: "/dashboard/create"
    },
    {
      key: "projects",
      icon: <FolderCopyOutlinedIcon />,
      label: "menu.myProjects",
      link: "/dashboard/projects"
    },
    settings.superPowers
      ? {
          key: "library",
          icon: <CollectionsBookmarkIcon />,
          label: "menu.myLibrary",
          link: "/dashboard/my-library"
        }
      : undefined
  ]
    .filter(Boolean)
    .map((i) => i as MenuItem);

export const getDrawerBottomMenuItems = (): MenuItem[] => [
  {
    key: "academy",
    icon: <SchoolIcon />,
    label: "menu.ufoacademy",
    externalLink: "https://academy.theufolab.com/"
  }
];

export const getDrawerEditorMenuItems = (settings: MenuSettings, onBack: () => void): MenuItem[] =>
  [
    {
      key: "back",
      icon: <KeyboardArrowLeftIcon />,
      className: "no-active",
      onClick: onBack
    },
    {
      key: "separator-1"
    },
    settings.superPowers
      ? {
          key: "library",
          label: "editor.library",
          icon: <CollectionsBookmarkIcon />,
          link: "/dashboard/projects/:uuid/v/:version/editor/library"
        }
      : null,
    {
      key: "text",
      label: "editor.text",
      icon: <TextIcon />,
      link: "/dashboard/projects/:uuid/v/:version/editor/text"
    },
    {
      key: "multimedia",
      label: "editor.multimedia",
      icon: <MultimediaIcon />,
      link: "/dashboard/projects/:uuid/v/:version/editor/multimedia"
    },
    {
      key: "elements",
      label: "editor.elements",
      icon: <ShapesIcon />,
      link: "/dashboard/projects/:uuid/v/:version/editor/elements"
    },
    {
      key: "components",
      label: "editor.components",
      icon: <SettingsApplicationsOutlinedIcon />,
      link: "/dashboard/projects/:uuid/v/:version/editor/components"
    },
    {
      key: "minigames",
      label: "editor.minigames",
      icon: <MinigameIcon />,
      link: "/dashboard/projects/:uuid/v/:version/editor/minigames"
    },
    {
      key: "ui",
      icon: <GamePadIcon />,
      label: "editor.ui",
      link: "/dashboard/projects/:uuid/v/:version/editor/ui"
    },
    {
      key: "settings",
      label: "editProject.settings.menu",
      icon: <SettingsApplicationsIcon />,
      link: "/dashboard/projects/:uuid/v/:version/settings"
    }
  ]
    .filter(Boolean)
    .map((i) => i as MenuItem);
