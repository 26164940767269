import { useContext } from "react";
import { LibraryContext } from "./LibraryContextProvider";

export const useLibrary = () => {
  const context = useContext(LibraryContext);

  if (!context) {
    throw new Error("useLibraryContext must be used within a LibraryContextProvider");
  }

  return context;
};
