import { createContext, useCallback, useEffect, useMemo, useState } from "react";

import { ApiClient, ApiRestError, type UserDto } from "@shared/api-client";
import { usePersistedState } from "@app/hooks";

const AUTH_TOKEN_KEY = "__authToken__";

export interface BackendContextType {
  setAuthToken: (token: string, remember?: boolean) => void;
  clearAuthToken: () => void;
  refresh: () => void;
  offline: boolean;
  ready: boolean;
  authToken?: string;
  backend?: ApiClient;
  user?: UserDto;
}

interface BackendProviderProps {
  apiBaseUrl: string;
  publicApiBaseUrl: string;
  children: React.ReactNode;
}

export const BackendContext = createContext<BackendContextType>({
  ready: false,
  offline: false,
  authToken: undefined,
  setAuthToken: () => {
    throw new Error("BackendProvider not in place");
  },
  clearAuthToken: () => {
    throw new Error("BackendProvider not in place");
  },
  refresh: () => {
    throw new Error("BackendProvider not in place");
  }
});

export const BackendProvider = ({ children, apiBaseUrl, publicApiBaseUrl }: BackendProviderProps) => {
  const [ready, setReady] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [backend, setBackend] = useState<ApiClient>();
  const [offline, setOffline] = useState(false);
  const [user, setUser] = useState<UserDto | undefined>(undefined);
  const [authToken, setAuthToken] = usePersistedState<string | undefined>(AUTH_TOKEN_KEY, undefined, "session");
  const [persistedAuthToken, setPersistedAuthToken] = usePersistedState<string | undefined>(AUTH_TOKEN_KEY);

  const clearAuthToken = useCallback(() => {
    setPersistedAuthToken(undefined);
    setAuthToken(undefined);
    setUser(undefined);
  }, [setPersistedAuthToken, setAuthToken, setUser]);

  const doSetAuthToken = useCallback(
    (token: string, remember = false) => {
      if (remember) {
        // next effect will set the actual AuthToken
        setPersistedAuthToken(remember ? token : undefined);
      } else {
        setPersistedAuthToken(undefined);
        setAuthToken(token);
      }
    },
    [setPersistedAuthToken, setAuthToken]
  );

  const refresh = useCallback(() => {
    setRefreshCounter((c) => c + 1);
  }, [setRefreshCounter]);

  useEffect(() => {
    const apiClient = new ApiClient(apiBaseUrl, publicApiBaseUrl);
    setBackend(apiClient);
  }, [apiBaseUrl, publicApiBaseUrl]);

  useEffect(() => {
    if (persistedAuthToken) {
      setAuthToken(persistedAuthToken);
    }
  }, [persistedAuthToken, setAuthToken]);

  // Fetch user when authToken changes
  useEffect(() => {
    if (backend) {
      setOffline(false);

      if (authToken) {
        setReady(false);
        backend.setToken(authToken);
        backend.user
          .get("/")
          .then(setUser)
          .catch((err) => {
            // Is token invalid?
            if (err instanceof ApiRestError && err.status === 401) {
              clearAuthToken();
              return;
            }

            // Offline?
            setOffline(true);

            setTimeout(() => {
              setReady(false);
              setOffline(false);
              refresh();
            }, 5000);
          })
          .finally(() => setReady(true));
      } else {
        backend.setToken(undefined);
        setUser(undefined);
        setReady(true);
      }
    }
  }, [authToken, backend, clearAuthToken, refresh, refreshCounter]);

  const backendContextValue = useMemo(
    () => ({
      backend,
      ready,
      offline,
      setAuthToken: doSetAuthToken,
      clearAuthToken,
      user,
      refresh,
      authToken
    }),
    [backend, ready, offline, doSetAuthToken, clearAuthToken, user, refresh, authToken]
  );

  return <BackendContext.Provider value={backendContextValue}>{backend ? children : null}</BackendContext.Provider>;
};
