import { useState, useCallback } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "@app/router";

import { type RegistrationFormData } from "@app/components";
import { RegistrationForm } from "@app/components";
import { useBackend, useTranslation, useTagManager } from "@app/hooks";

export default function LoginPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { auth } = useBackend();
  const navigate = useNavigate();
  const tm = useTagManager();

  const [error, setError] = useState("");

  const handleSubmit = useCallback(
    async (data: RegistrationFormData): Promise<boolean> => {
      setError("");
      try {
        const user = await auth.post("/register", {
          email: data.email,
          password: data.password,
          username: data.username
        });

        tm("sign_up", { status: "ok", user_id: user.id });
        navigate("/auth/register-success");
        return true;
      } catch (error) {
        tm("sign_up", { status: "ko" });

        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError(t("register.error").toString());
        }

        return false;
      }
    },
    [auth, navigate, t, tm]
  );

  return (
    <>
      <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 0, 0) }}>
        {t("home.createAccount")}
      </Typography>

      <RegistrationForm errors={error ? { root: error } : undefined} onSubmit={handleSubmit} />

      <Box display='block' sx={{ marginTop: theme.spacing(1), width: "100%" }}>
        <Typography display='block' textAlign='center'>
          {t("registration.alreadyAccount")} <Link to='/auth/login'>{t("registration.goToLogin")}</Link>
        </Typography>
      </Box>
    </>
  );
}
