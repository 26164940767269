import { SearchBox } from "@app/components";
import { LibraryPickerList } from "./LibraryPickerList";
import { useEffect, useState } from "react";
import { useBackend } from "@app/hooks/useBackend";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "@app/hooks";

interface Props {
  libraryUuid: string;
  allowSearch?: boolean;
}

function SectionBox({
  title,
  children,
  onClick,
  expanded
}: {
  title: string;
  children: React.ReactNode;
  onClick?: () => void;
  expanded?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        {expanded && (
          <Tooltip title={t("library.backToCategories")}>
            <IconButton onClick={onClick}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        )}
        <Typography variant='h6' flexGrow={1}>
          {title}
        </Typography>
        {!expanded && (
          <Tooltip title={t("library.expandCategory")}>
            <IconButton onClick={onClick}>
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {children}
    </Box>
  );
}

export function LibraryPickerCategories(props: Props) {
  const { libraryUuid, allowSearch } = props;
  const { library } = useBackend();

  const [search, setSearch] = useState<string>("");
  const [categories, setCategories] = useState<string[]>();
  const [expandedCategory, setExpandedCategory] = useState<string>();

  useEffect(() => {
    library
      .getList({ path: "/:uuid/categories", params: { uuid: libraryUuid } })
      .then((categories) => setCategories(categories.items));
  }, [library, libraryUuid]);

  return (
    <>
      {allowSearch && <SearchBox onSearch={setSearch} persistingKey='library-search' />}
      {!expandedCategory &&
        categories &&
        categories.filter(Boolean).map((category) => (
          <SectionBox key={category} title={category} onClick={() => setExpandedCategory(category)}>
            <LibraryPickerList
              libraryUuid={libraryUuid}
              category={category}
              disposition='horizontal'
              searchTerm={search}
            />
          </SectionBox>
        ))}
      {expandedCategory && (
        <SectionBox title={expandedCategory} onClick={() => setExpandedCategory(undefined)} expanded>
          <LibraryPickerList
            libraryUuid={libraryUuid}
            category={expandedCategory}
            disposition='masonry'
            searchTerm={search}
          />
        </SectionBox>
      )}
    </>
  );
}
