import { Box, styled, Tooltip } from "@mui/material";
import { useDrag } from "react-dnd";

import { GamePluginType } from "@shared/utils/plugins";

import type { EditorDropItem } from "@app/types";
import { Image } from "@app/components";

import QuestionMarkIcon from "@mui/icons-material/HelpOutline";
import { type LibraryItemDto } from "@shared/api-client";
import { useMemo } from "react";

export const LibraryItemBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(4, 2),
  cursor: "pointer",
  borderRadius: theme.shape.borderRadius,

  transition: theme.transitions.create(["background-color", "transform"], {
    duration: theme.transitions.duration.shortest
  }),

  "&:hover": {
    backgroundColor: theme.palette.action.disabledBackground,

    ">.MuiCard-root:hover": {
      transform: "none"
    }
  },

  "&.in-use": {
    fontWeight: "bold",
    color: theme.palette.primary.main
  }
}));

const ComponentItemImage = styled(Image)({
  width: "100%",
  height: "100%",
  objectFit: "contain"
});

interface Props {
  item: LibraryItemDto;
  onSelect?: (id: string, data: LibraryItemDto) => void;
  onDragStart?: (item: LibraryItemDto) => void;
  onDragEnd?: () => void;
  tooltip?: boolean;
}

export function LibraryItem({ item, onSelect, onDragStart, onDragEnd }: Props) {
  // Drag component
  const [, drag] = useDrag<EditorDropItem>(() => ({
    type: GamePluginType.GAME_COMPONENT,
    item: () => {
      onDragStart?.(item);
      return {
        ...(item.configuration as EditorDropItem),
        component: item.component
      };
    },
    end: onDragEnd
  }));

  const tooltipContent = useMemo(() => {
    const title = (
      <p style={{ textAlign: "center", margin: 0 }}>
        <strong>{item.name}</strong>
      </p>
    );

    if (item.description) {
      return (
        <>
          {title}
          <p style={{ margin: 0 }}>{item.description}</p>
        </>
      );
    }

    return title;
  }, [item.description, item.name]);

  return (
    <Tooltip title={tooltipContent} placement='bottom'>
      <LibraryItemBox onClick={() => onSelect?.(item.uuid, item)} ref={drag}>
        {item.thumbnail ? (
          <ComponentItemImage src={item.thumbnail} alt={item.name} boxSx={{ height: "100%" }} />
        ) : (
          <QuestionMarkIcon fontSize='large' sx={{ opacity: 0.3 }} />
        )}
      </LibraryItemBox>
    </Tooltip>
  );
}
