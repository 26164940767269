import { useCallback, useEffect, useState } from "react";
import Masonry from "react-responsive-masonry";
import { Box, Paper, styled, Tab, type TabProps, Tabs, Tooltip, useTheme } from "@mui/material";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";

import { type LibraryItemDto } from "@shared/api-client";

import { useDevice, useTranslation } from "@app/hooks";
import { Image, PageLoading, Prompt, SectionTitle } from "@app/components";
import { useLibrary } from "@app/library";
import { AccessLevel } from "@app/types";
import { EditLibraryItemModal } from "@app/library/EditLibraryItemModal";

import LockIcon from "@mui/icons-material/Lock";
import AddIcon from "@mui/icons-material/Add";

const NEW_KEYWORD = "**new**";
const NULL_KEYWORD = "**null**";
const DND_PROVIDER_OPTIONS = {};

const ContainerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: theme.spacing(2)
}));

const StyledItemBox = styled(Box)(({ theme }) => ({
  transition: "transform 0.2s",
  cursor: "pointer",

  img: {
    background: theme.palette.grey[100],
    padding: theme.spacing(1),
    boxSizing: "border-box",
    width: "100%",
    borderRadius: 8,
    overflow: "hidden",
    transition: "box-shadow 0.2s"
  },

  p: {
    marginTop: theme.spacing(0.5),
    fontSize: "0.8rem",
    textAlign: "center",

    "& svg": {
      fontSize: "1rem",
      verticalAlign: "middle"
    }
  },

  ":hover": {
    transform: "scale(1.025)",
    img: {
      boxShadow: theme.shadows[4]
    }
  }
}));

function ItemBox({ item, onSelect }: { item: LibraryItemDto; onSelect: (item: LibraryItemDto) => void }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "library-item",
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    item: () => item
  }));

  return (
    <StyledItemBox ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }} onClick={() => onSelect(item)}>
      <Image src={item.thumbnail} alt={item.name} draggable={false} />
      <p>
        {item.name} {item.accessLevel === AccessLevel.PRIVATE ? <LockIcon color='error' /> : null}
      </p>
    </StyledItemBox>
  );
}

function DroppableTab({
  onDropItem,
  tooltip,
  label,
  ...props
}: TabProps & { onDropItem: (item: LibraryItemDto) => void; tooltip?: string }) {
  const [droppedItem, setDroppedItem] = useState<LibraryItemDto | undefined>();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "library-item",
    drop: setDroppedItem,
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }));

  useEffect(() => {
    if (droppedItem) {
      onDropItem(droppedItem);
      setDroppedItem(undefined);
    }
  }, [droppedItem, onDropItem]);

  const labelElement = tooltip ? (
    <Tooltip title={tooltip}>
      <span>{label}</span>
    </Tooltip>
  ) : (
    label
  );

  return (
    <Tab
      {...props}
      label={labelElement}
      ref={drop}
      style={{ backgroundColor: isOver ? "rgba(0, 0, 0, 0.1)" : "transparent" }}
    />
  );
}

export default function LibraryCollectionContentPage() {
  const { t, ts } = useTranslation();
  const { getCategories, getCollectionItems, updateCollectionItem } = useLibrary();
  const theme = useTheme();
  const { isTouch } = useDevice();

  const [items, setItems] = useState<LibraryItemDto[] | undefined>();
  const [selectedItem, setSelectedItem] = useState<LibraryItemDto | undefined>();
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>();

  const [categories, setCategories] = useState<string[] | undefined>();

  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [itemToMoveCategory, setItemToMoveCategory] = useState<LibraryItemDto | undefined>();

  const [refresh, setRefresh] = useState(0);

  const doRefresh = useCallback(() => setRefresh((prev) => prev + 1), []);

  useEffect(() => {
    // Get categories
    getCategories().then((categories) => {
      setCategories(categories);
    });
  }, [getCategories, refresh]);

  useEffect(() => {
    if (selectedCategory === undefined && categories && categories.length > 0) {
      setSelectedCategory(categories[0]);
    }
  }, [selectedCategory, categories]);

  useEffect(() => {
    setItems(undefined);

    if (selectedCategory === undefined) {
      return;
    }

    const category = selectedCategory === NULL_KEYWORD ? "null" : selectedCategory;

    // Get collection items
    getCollectionItems({ category: [category] }).then(setItems);
  }, [getCollectionItems, selectedCategory, refresh]);

  const handleMoveItemToCategory = useCallback(
    (item: LibraryItemDto, category: string | null) => {
      updateCollectionItem(item.uuid, { category }).then(doRefresh);
    },
    [doRefresh, updateCollectionItem]
  );

  const handleAddCategory = useCallback(
    (newCategoryName: string) => {
      setShowAddCategoryModal(false);

      // TODO: Actually do the thing in the backend
      setCategories((prevCategories) => [...(prevCategories || []), newCategoryName]);

      if (itemToMoveCategory === undefined) {
        return;
      }

      handleMoveItemToCategory(itemToMoveCategory, newCategoryName);
    },
    [handleMoveItemToCategory, itemToMoveCategory]
  );

  const handleCancelAddCategory = useCallback(() => {
    setShowAddCategoryModal(false);
    setItemToMoveCategory(undefined);
  }, []);

  const handleCategoryChange = useCallback((_event: React.SyntheticEvent, newValue: string) => {
    if (newValue === NEW_KEYWORD) {
      setShowAddCategoryModal(true);
      return;
    }

    setSelectedCategory(newValue);
  }, []);

  const handleNewCategoryDrop = useCallback((item: LibraryItemDto) => {
    setItemToMoveCategory(item);
    setShowAddCategoryModal(true);
  }, []);

  const createCategoryDropHandler = useCallback(
    (targetCategory: string) => (item: LibraryItemDto) => {
      if (targetCategory === NULL_KEYWORD) {
        handleMoveItemToCategory(item, null);
        return;
      }

      handleMoveItemToCategory(item, targetCategory);
    },
    [handleMoveItemToCategory]
  );

  if (!categories) {
    return <PageLoading />;
  }

  const sortedCategories =
    categories.length > 0 ? [...categories].sort((a, b) => (a || "*").localeCompare(b || "*")) : [""];

  return (
    <Paper sx={{ padding: theme.spacing(8) }} elevation={0}>
      <SectionTitle title={t("library.content.items")} info={t("library.content.itemsHelp")} />

      <DndProvider
        key={isTouch ? "touch" : "mouse"} // Force re-render on touch/mouse change
        backend={isTouch ? TouchBackend : HTML5Backend}
        options={DND_PROVIDER_OPTIONS}
      >
        <ContainerBox>
          <Tabs
            orientation='horizontal'
            value={selectedCategory || NULL_KEYWORD}
            onChange={handleCategoryChange}
            style={{ marginBottom: theme.spacing(2) }}
          >
            <DroppableTab value={NEW_KEYWORD} icon={<AddIcon />} onDropItem={handleNewCategoryDrop} />
            {sortedCategories.map((category) => (
              <DroppableTab
                key={category || "Empty"}
                value={category || NULL_KEYWORD}
                label={category || ts("library.uncategorized")}
                onDropItem={createCategoryDropHandler(category)}
              />
            ))}
          </Tabs>

          {items && (
            <Masonry columnsCount={6} gutter='16px'>
              {items.map((item) => (
                <ItemBox key={item.uuid} item={item} onSelect={setSelectedItem} />
              ))}
            </Masonry>
          )}

          {!items && <PageLoading />}
        </ContainerBox>
      </DndProvider>

      <EditLibraryItemModal
        item={selectedItem}
        categories={categories}
        onClose={(refresh) => {
          setSelectedItem(undefined);
          if (refresh) {
            doRefresh();
          }
        }}
      />

      <Prompt
        open={showAddCategoryModal}
        title={ts("library.addCategory")}
        message={t("library.addCategoryMessage")}
        onSubmit={handleAddCategory}
        onCancel={handleCancelAddCategory}
        confirmText={ts("common.add")}
      />
    </Paper>
  );
}
