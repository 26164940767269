import { useCallback, useEffect, useMemo, useState } from "react";

import type { LibraryItemDto } from "@shared/api-client";
import { ModalSection, MultiSectionModal } from "@shared/form-builder/common/MultiSectionModal";
import { Form } from "@shared/form-builder";

import type { AccessLevel } from "@app/types";
import { useAppContext, useTranslation } from "@app/hooks";
import { getLibraryItemSchema, getLibraryItemUISchema } from "@app/schemas/library.item.schema";
import { Button } from "@app/components";

import { useLibrary } from "./useLibrary";
import { type CreateCollectionItemFormData } from "./types";
import { Box } from "@mui/material";

interface Props {
  item?: LibraryItemDto;
  categories?: string[];
  onClose: (requestRefresh?: boolean) => void;
}

type FormData = Partial<CreateCollectionItemFormData>;

export function EditLibraryItemModal(props: Props) {
  const { item, categories = [], onClose } = props;
  const { confirm } = useAppContext();
  const { t, ts } = useTranslation();
  const { updateCollectionItem, deleteCollectionItem } = useLibrary();

  const [currentData, setCurrentData] = useState<FormData>();
  const [isDirty, setIsDirty] = useState(false);

  const handleChange = useCallback((data: FormData) => {
    setCurrentData(data);
    setIsDirty(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (item && currentData) {
      try {
        await updateCollectionItem(item?.uuid, currentData);
        setIsDirty(false);
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  }, [currentData, item, onClose, updateCollectionItem]);

  const handleDelete = useCallback(async () => {
    if (item) {
      await confirm({
        title: t("library.confirmDeleteItemTitle"),
        content: t("library.confirmDeleteItemMessage", { name: item.name })
      });

      try {
        deleteCollectionItem(item?.uuid);
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  }, [confirm, deleteCollectionItem, item, onClose, t]);

  useEffect(() => {
    if (item) {
      setCurrentData({
        name: item.name,
        description: item.description,
        category: item.category,
        accessLevel: item.accessLevel as AccessLevel,
        thumbnail: item.thumbnail
      });
    } else {
      setCurrentData(undefined);
    }

    setIsDirty(false);
  }, [item]);

  const footer = useMemo(
    () => (
      <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%' gap={2}>
        <Button onClick={handleDelete} color='error' variant='contained'>
          {t("common.delete")}
        </Button>
        <Box flexGrow={1} />
        <Button onClick={() => onClose()}>{t("common.cancel")}</Button>
        <Button variant='contained' color='primary' onClick={handleSubmit} disabled={!isDirty}>
          {t("common.save")}
        </Button>
      </Box>
    ),
    [handleDelete, handleSubmit, isDirty, onClose, t]
  );

  const schemaContext = useMemo(() => ({ t, ts, collections: [], categories }), [categories, t, ts]);

  return (
    <MultiSectionModal open={!!item} onClose={() => onClose()} footer={footer}>
      <ModalSection id='main' title={t("library.editItem.modalTitle")}>
        {currentData && (
          <Form
            data={currentData}
            schema={getLibraryItemSchema(schemaContext)}
            uiSchema={getLibraryItemUISchema(schemaContext, { hideCollection: true })}
            onChange={handleChange}
          />
        )}
      </ModalSection>
    </MultiSectionModal>
  );
}
