import { createElement, useEffect, useState, type HTMLProps } from "react";
import DOMPurify from "dompurify";

import { renderMD } from "./utils/render";

interface Props extends HTMLProps<HTMLDivElement> {
  content: string;
  component?: string;
}

export function MDViewer(props: Props) {
  const { content, component = "div", ...rest } = props;

  const [html, setHtml] = useState("");

  useEffect(() => {
    try {
      setHtml(DOMPurify.sanitize(renderMD(content)));
    } catch (e: unknown) {
      setHtml("Error rendering Markdown: " + String(e));
    }
  }, [content]);

  return createElement(component, {
    ...rest,
    dangerouslySetInnerHTML: { __html: html },
  });
}
