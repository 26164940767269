import React, {
  type CSSProperties,
  useRef,
  useState,
  useCallback,
  useEffect,
  useContext,
  type ReactNode,
  useMemo
} from "react";
import Moveable, {
  type OnDrag,
  type OnDragOrigin,
  type OnResize,
  type OnRotate,
  type OnEvent,
  type OnScale,
  type OnDragGroup,
  type OnScaleGroup,
  type OnRotateGroup,
  type OnResizeGroup,
  type ScalableOptions,
  type ResizableOptions
} from "react-moveable";
import { type SxProps, Box, styled } from "@mui/material";

import {
  type GameElementStageProps,
  type GameElement,
  type GameScreenSettings,
  findElementById,
  hasActions
} from "@shared/game-engine";
import { ComponentContext } from "@shared/game-player";
import { getTransforms, scaleTransforms, unscaleTransforms } from "@shared/game-player/utils/styles";
import { type PluginManifest } from "@shared/utils/plugins";

import { useShortcuts } from "@app/hooks";
import { EDITOR_ACTION_AREA, EDITOR_SAFE_AREA } from "@app/config";

import AnimationIcon from "@mui/icons-material/Animation";
import ActionsIcon from "@mui/icons-material/AdsClick";

const DOUBLE_CLICK_DELAY = 300;
const HANDLER_SNAP_DIRECTIONS = { left: true, right: true, top: true, bottom: true, center: true };
const HANDLER_SNAP_ROTATIONS = [0, 45, 90, 135, 180, 225, 270, 315];
const RESIZE_OPTIONS: ResizableOptions = {
  renderDirections: ["n", "s", "w", "e"]
};
const SCALE_OPTIONS: ScalableOptions = {
  renderDirections: ["nw", "ne", "sw", "se"],
  keepRatio: true
};

type SupportedEvents =
  | OnDrag
  | OnResize
  | OnScale
  | OnRotate
  | OnDragOrigin
  | OnDragGroup
  | OnScaleGroup
  | OnRotateGroup
  | OnResizeGroup;

interface Props {
  screen: GameScreenSettings;
  scaleModifier?: boolean;
  active?: boolean;
  layout?: GameElement[];
  pan?: { x: number; y: number };
  zoom: number;
  sx?: SxProps;
  selection?: string[];
  showMeta?: boolean;
  onSetSelection: (selection: string[]) => void;
  onDoubleClick?: (id: string, manifest: PluginManifest | null, event: React.MouseEvent) => void;
  onElementUpdate: (id: string, stage: GameElementStageProps) => void;
}

interface HandlerProps {
  element: GameElement;
  zoom: number;
  scaledStage: GameElementStageProps;
  meta?: ReactNode;
  selected?: boolean;
  invisible?: boolean;
  onSelect?: (id: string, event: React.MouseEvent) => void;
  onResize?: () => void;
}

const ContainerBox = styled(Box)(() => ({
  position: "absolute",
  pointerEvents: "auto",

  ".moveable-area, .editor-handler": {
    cursor: "move"
  },

  ".moveable-scalable": {
    backgroundColor: `#666 !important`
  },

  ".editor-selectable": {
    "&:hover, &.show-meta": {
      outline: "1px solid rgba(0, 0, 0, .5)",
      border: "1px solid rgba(0, 0, 0, .5)"
    },

    "> .meta": {
      position: "absolute",
      opacity: 0.7,
      top: -20,
      left: -1,
      padding: "1px 2px",
      fontSize: "10px",
      lineHeight: "9px",
      color: "white",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 1,

      span: {
        display: "block",
        background: "black",
        padding: "3px",
        borderRadius: "2px"
      }
    },
    "&.show-outline": {
      outline: "1px dashed gray !important"
    }
  }
}));

function ElementMeta({ element, index }: { element: GameElement; index: number }) {
  // calculate some indicators
  const actions = hasActions(element);
  const conditions = !!element.condition?.trim();
  const animations = Object.values(element.transitions || {}).some((tr) => !!tr.name);

  return (
    <>
      <span>{[1 + index, element.lockId || element.itemId].filter(Boolean).join(" - ")}</span>
      {animations && <AnimationIcon fontSize='small' color='success' />}
      {(actions || conditions) && <ActionsIcon fontSize='small' color='error' />}
    </>
  );
}

function Handler(props: HandlerProps) {
  const { element, scaledStage, meta, onSelect, onResize, selected, zoom, invisible } = props;

  const {
    plugins: { getPluginManifest }
  } = useContext(ComponentContext);

  const [manifest, setManifest] = useState<PluginManifest<Record<string, unknown>> | null>(null);
  const [bounds, setBounds] = useState<DOMRect | null>(null);
  const [resize, setResize] = useState(false);

  const autoWidth = !!manifest?.features.autoWidth;
  const autoHeight = !!manifest?.features.autoHeight;
  const missingWidth = element.stage.width === null && !manifest?.features.autoWidth;
  const missingHeight = element.stage.height === null && !manifest?.features.autoHeight;

  // Load the manifest
  useEffect(() => {
    getPluginManifest(element.component)
      .then(setManifest)
      .catch(() => setManifest(null)); // Ignore errors
  }, [element.component, getPluginManifest]);

  // Auto calculate (with retry) the size of the element
  useEffect(() => {
    if (!missingWidth && !missingHeight && !autoWidth && !autoHeight) {
      return;
    }

    let timeout: ReturnType<typeof setTimeout> | null = null;

    function recalculate() {
      const htmlEl = document.getElementById(`el-${element.id}`);

      if (htmlEl) {
        const updateBounds = () => {
          // remove rotation temporary from transforms
          const sx = getTransforms(scaledStage).transform;
          htmlEl.style.transform = sx?.replace(/rotate\([^)]+\)/, "") || "";

          // update bounds
          const newBounds = htmlEl.getBoundingClientRect();

          if (!bounds || bounds.width !== newBounds.width || bounds.height !== newBounds.height) {
            // update bounds object ONLY if changed
            setBounds(newBounds);
          }

          // restore original transforms
          htmlEl.style.transform = "";
        };

        // Listen for resize changes
        const observer = new ResizeObserver(updateBounds);
        observer.observe(htmlEl);
        updateBounds();

        return () => observer.disconnect();
      } else {
        // The element is not yet rendered, retry
        timeout = setTimeout(recalculate, 100);
      }
    }

    recalculate();
    setResize(false);

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [autoHeight, autoWidth, element.id, missingHeight, missingWidth, zoom, resize, scaledStage, bounds]);

  // Refresh the handler
  useEffect(() => {
    if (!bounds) {
      return;
    }

    onResize?.();
  }, [bounds, zoom, onResize]);

  const sx: CSSProperties = useMemo(
    () => ({
      position: "absolute",

      ...getTransforms({
        ...scaledStage,
        // Use actual bounds if autoSize/autoHeight is enabled or missing width/height
        width: autoWidth || missingWidth ? (bounds ? bounds.width / scaledStage.scale : null) : scaledStage.width,
        height: autoHeight || missingHeight ? (bounds ? bounds.height / scaledStage.scale : null) : scaledStage.height
      }),

      boxSizing: "border-box",
      padding: "4px"
    }),
    [autoHeight, autoWidth, bounds, missingHeight, missingWidth, scaledStage]
  );

  const classes = ["editor-selectable"];

  if (meta) {
    classes.push("show-meta");
  }

  if (invisible) {
    classes.push("show-outline");
  }

  if (selected) {
    classes.push("editor-handler");
  }

  return (
    <div style={sx} className={classes.join(" ")} onClick={(e) => onSelect?.(element.id, e)} data-id={element.id}>
      {meta !== undefined ? (
        <div
          className='meta'
          style={{ transformOrigin: "bottom left", transform: `scale(${1 / element.stage.scale})` }}
        >
          {meta}
        </div>
      ) : null}
    </div>
  );
}

export function HandlersContainer(props: Props) {
  const {
    screen,
    layout,
    selection = [],
    pan = { x: 0, y: 0 },
    zoom,
    sx,
    active,
    scaleModifier,
    onSetSelection,
    onElementUpdate,
    onDoubleClick,
    showMeta
  } = props;

  const handlerContainerRef = useRef<HTMLDivElement | null>(null);
  const moveable = useRef<Moveable | null>(null);

  const [continueSelect, setContinueSelect] = useState(false);
  const [lockAspectRatio, setLockAspectRatio] = useState(false);
  const [isScaling, setIsScaling] = useState(false);

  const [lastClick, setLastClick] = useState<{ target: string; time: number }>();
  const [horizontalGuides, setHorizontalGuides] = useState<number[]>([]);
  const [verticalGuides, setVerticalGuides] = useState<number[]>([]);

  const [requireUpdate, setRequireUpdate] = useState(false);

  const [dirty, setDirty] = useState(false);

  const {
    plugins: { getPluginManifest, getCachedPluginManifest }
  } = useContext(ComponentContext);

  // Manifest of the plugin when a single element is selected
  const [manifest, setManifest] = useState<PluginManifest<Record<string, unknown>> | null>(null);

  /**
   * Handle selection of elements
   */
  const handleSelect = useCallback(
    (id: string, event: React.MouseEvent) => {
      const isLeftClick = event.button === 0;

      if (isLeftClick && lastClick?.target === id && Date.now() - lastClick?.time < DOUBLE_CLICK_DELAY) {
        onDoubleClick?.(id, manifest, event);
      } else {
        if (continueSelect) {
          onSetSelection([...selection, id]);
        } else {
          onSetSelection([id]);
        }
      }
      setLastClick({ target: id, time: Date.now() });
      event.stopPropagation();
    },
    [continueSelect, lastClick?.target, lastClick?.time, manifest, onDoubleClick, onSetSelection, selection]
  );

  /**
   * Handle resize of the container
   */
  const handleResize = useCallback(() => {
    moveable.current?.updateRect();
    setRequireUpdate(true);
    // Some delay to help with the resize
    const timeout = setTimeout(() => {
      moveable.current?.updateRect();
      setRequireUpdate(false);
    }, 10);

    return () => clearTimeout(timeout);
  }, []);

  /**
   * Initialize the scale of the element:
   * - Keep the aspect ratio
   *
   * ScaleOptions keepRatio is not working as expected
   */
  const handleInitScale = useCallback(() => {
    setIsScaling(true);
  }, []);

  /**
   * Consolidate the changes of the transforms into the game level
   */
  const consolidate = useCallback(
    (ev: OnEvent) => {
      setIsScaling(false);

      if (!dirty) {
        // Avoid unnecessary updates if nothing has changed
        return;
      }

      function processTarget(target: HTMLElement | SVGElement) {
        const styles = target.style;
        const id = target.getAttribute("data-id");
        const element = layout?.find((element) => element.id === id);

        if (!id || !element) {
          console.warn("Element not found", id);
          return;
        }

        const prevStage = element.stage;

        // Parse styles into actual values
        const [x, y] = (styles.transform.match(/translate\(([^,]+)px, ([^,]+)px\)/)?.slice(1) || [0, 0]).map((value) =>
          Number(value)
        );

        // Considering autoWidth and autoHeight. Respective width/height must be null if true
        const manifest = getCachedPluginManifest(element.component);
        const width = manifest?.features.autoWidth ? null : parseFloat(styles.width.replace("px", ""));
        const height = manifest?.features.autoHeight ? null : parseFloat(styles.height.replace("px", ""));

        const rotation = parseFloat(styles.transform.match(/rotate\(([^deg]+)deg\)/)?.[1] || "0");
        const scale = parseFloat(styles.transform.match(/scale\(([^)]+)\)/)?.[1] || "0");
        const [originX, originY] = styles.transformOrigin
          .split(" ")
          .map((value) => parseFloat(value.replace("%", "")) / 100);

        const stage: GameElementStageProps = { ...prevStage, x, y, width, height, rotation, originX, originY, scale };

        onElementUpdate(id, unscaleTransforms(stage, zoom, { x: pan.x, y: pan.y }));
      }

      if ("targets" in ev) {
        // Fixme: Missing type?
        (ev.targets as (HTMLElement | SVGElement)[]).forEach((target) => processTarget(target));
      } else {
        processTarget(ev.target);
      }

      setDirty(false);
    },
    [dirty, getCachedPluginManifest, layout, onElementUpdate, pan.x, pan.y, zoom]
  );

  /**
   * Handle the update of the moveable element
   *
   * @param ev
   */
  const handleUpdateMoveable = useCallback((ev: SupportedEvents) => {
    function processEvent<T extends SupportedEvents>(ev: T) {
      if ("width" in ev && "height" in ev) {
        ev.target.style.width = `${ev.width}px`;
        ev.target.style.height = `${ev.height}px`;
      }
      if ("drag" in ev) {
        ev.target.style.transform = ev.drag.transform;
      } else if ("transform" in ev) {
        ev.target.style.transform = ev.transform;
      }

      if ("transformOrigin" in ev) {
        ev.target.style.transformOrigin = ev.transformOrigin;
        ev.target.style.transform = ev.drag.transform;
      }
    }

    if ("events" in ev) {
      ev.events.forEach((ev) => processEvent(ev));
    } else {
      processEvent(ev);
    }

    setDirty(true);
  }, []);

  useShortcuts({
    // Handle shift key for continue selection & lock aspect ratio
    shift: [
      () => {
        setContinueSelect(true);
        setLockAspectRatio(true);
      },
      () => {
        setContinueSelect(false);
        setLockAspectRatio(false);
      }
    ]
  });

  // Handle selection change
  useEffect(() => {
    moveable.current?.updateSelectors();
  }, [selection]);

  useEffect(() => {
    moveable.current?.updateRect();
  }, [layout]);

  // Update guidelines
  useEffect(() => {
    // Base guidelines
    const v = [0, screen.width / 2, screen.width];
    const h = [0, screen.height / 2, screen.height];

    // safe area
    if (EDITOR_SAFE_AREA > 0) {
      v.push(screen.width * EDITOR_SAFE_AREA, screen.width * (1 - EDITOR_SAFE_AREA * 2));
      h.push(screen.height * EDITOR_SAFE_AREA, screen.height * (1 - EDITOR_SAFE_AREA * 2));
    }

    // action area
    if (EDITOR_ACTION_AREA > 0) {
      v.push(screen.width * EDITOR_ACTION_AREA, screen.width * (1 - EDITOR_ACTION_AREA * 2));
      h.push(screen.height * EDITOR_ACTION_AREA, screen.height * (1 - EDITOR_ACTION_AREA * 2));
    }

    layout?.forEach(({ id, stage }) => {
      v.push(stage.x);
      h.push(stage.y);

      // element bounds (does this need to rescale?)
      const bounds = document.getElementById(`el-${id}`)?.getBoundingClientRect();
      if (bounds) {
        v.push(stage.x + bounds.width / 2, stage.x + bounds.width - 1);
        h.push(stage.y + bounds.height / 2, stage.y + bounds.height - 1);
      }
    });

    setHorizontalGuides([...new Set(h)].map((value) => pan.y + value * zoom));
    setVerticalGuides([...new Set(v)].map((value) => pan.x + value * zoom));
  }, [screen, layout, zoom, pan.y, pan.x]);

  // Load the manifest
  useEffect(() => {
    if (layout && selection.length === 1) {
      const selectedElement = findElementById(layout, selection[0]);

      if (selectedElement) {
        getPluginManifest(selectedElement.component)
          .then(setManifest)
          .catch(() => setManifest(null)); // Ignore errors

        return;
      }
    }
  }, [selection, getPluginManifest, layout]);

  // Calculate tool constraints when single selection
  const autoWidth = !!manifest?.features.autoWidth;
  const autoHeight = !!manifest?.features.autoHeight;

  const resizeOptions = useMemo(() => {
    const renderDirections = [];

    if (!autoWidth) {
      renderDirections.push("w", "e");
    }

    if (!autoHeight) {
      renderDirections.push("n", "s");
    }

    if (!autoWidth && !autoHeight) {
      renderDirections.push("nw", "ne", "sw", "se");
    }

    return { ...RESIZE_OPTIONS, renderDirections };
  }, [autoHeight, autoWidth]);

  const fullResizable = !autoWidth && !autoHeight;
  const keepRatio = lockAspectRatio || isScaling || manifest?.features.lockAspectRatio;
  const finalSx = useMemo(() => ({ ...sx, opacity: requireUpdate ? 0 : 1 }), [requireUpdate, sx]);
  const isSingleSelection = selection.length === 1;

  return (
    <ContainerBox ref={handlerContainerRef} sx={finalSx}>
      {layout?.map((element, index) => (
        <Handler
          key={element.id}
          element={element}
          scaledStage={scaleTransforms(element.stage, zoom, pan)}
          onSelect={handleSelect}
          onResize={handleResize}
          meta={
            showMeta || selection.includes(element.id) ? <ElementMeta element={element} index={index} /> : undefined
          }
          selected={selection.includes(element.id)}
          zoom={zoom}
          invisible={element.stage.opacity < 0.1}
        />
      ))}
      <Moveable
        target='.editor-handler'
        container={handlerContainerRef.current}
        ref={moveable}
        origin={false}
        originDraggable={false}
        draggable={active}
        rotatable={active}
        resizable={active && !scaleModifier && isSingleSelection ? resizeOptions : false}
        scalable={active && (!fullResizable || scaleModifier || !isSingleSelection) ? SCALE_OPTIONS : false}
        onScaleStart={handleInitScale}
        onDrag={handleUpdateMoveable}
        onResize={handleUpdateMoveable}
        onScale={handleUpdateMoveable}
        onRotate={handleUpdateMoveable}
        onDragGroup={handleUpdateMoveable}
        onScaleGroup={handleUpdateMoveable}
        onRotateGroup={handleUpdateMoveable}
        onResizeGroup={handleUpdateMoveable}
        onDragEnd={consolidate}
        onResizeEnd={consolidate}
        onRotateEnd={consolidate}
        onScaleEnd={consolidate}
        onDragGroupEnd={consolidate}
        onScaleGroupEnd={consolidate}
        onRotateGroupEnd={consolidate}
        onResizeGroupEnd={consolidate}
        throttleResize={1}
        horizontalGuidelines={horizontalGuides}
        verticalGuidelines={verticalGuides}
        snappable
        keepRatio={keepRatio}
        snapThreshold={10 / zoom}
        snapDirections={HANDLER_SNAP_DIRECTIONS}
        snapRotationDegrees={HANDLER_SNAP_ROTATIONS}
        snapRotationThreshold={3}
        isDisplaySnapDigit={true}
        triggerAblesSimultaneously={true} // Allow scale + resize at the same time
      />
    </ContainerBox>
  );
}
