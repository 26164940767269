import { type FC } from "react";

import {
  type PluginManifest,
  type PluginComponentBaseProps,
} from "@shared/utils/plugins";

import { type PluginComponent } from "../types/GameComponent";

export function createPluginComponent<P>(
  fc: FC<PluginComponentBaseProps<P>>,
  manifestPathOrContent: string | PluginManifest<P>
): PluginComponent<P> {
  const pluginModule = Object.assign(fc, {
    getManifest: async () =>
      typeof manifestPathOrContent === "string"
        ? (await import(/* @vite-ignore */ manifestPathOrContent)).default
        : manifestPathOrContent,
  });

  return pluginModule;
}
