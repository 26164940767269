import { CardContent, styled, type SxProps, Typography } from "@mui/material";
import { ProjectCardWrapper } from "@app/components";

interface Props {
  onClick?: () => void;
  Icon?: React.FC<{ sx: SxProps }>;
  title: string;
  subtitle?: string;
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: theme.palette.divider,
  borderRadius: "8px",
  margin: theme.spacing(2)
}));

export function BlankCard({ onClick, title, subtitle, Icon }: Props) {
  return (
    <ProjectCardWrapper onClick={onClick}>
      <StyledCardContent>
        {Icon && <Icon sx={{ fontSize: "64px", opacity: 0.5 }} />}
        <Typography variant='h6'>{title}</Typography>
        {subtitle && <Typography variant='subtitle1'>{subtitle}</Typography>}
      </StyledCardContent>
    </ProjectCardWrapper>
  );
}
