import { useState } from "react";
import {
  LayoutProps,
  RankedTester,
  UISchemaElement,
  rankWith,
} from "@jsonforms/core";
import { renderLayoutElements } from "@jsonforms/material-renderers";
import { withJsonFormsLayoutProps } from "@jsonforms/react";

import isEmpty from "lodash/isEmpty";

import { Box, Tab, Tabs } from "@mui/material";

import { FieldWrapper } from "../common/FieldWrapper";
import { Icons } from "../common/Icons";
import { isLayoutType } from "../utils";
import { TabsLayout } from "../types";

export const TabsLayoutRenderer = (props: LayoutProps) => {
  const { uischema, renderers, cells, schema, path, enabled, visible } = props;
  const layout = uischema as TabsLayout;
  const [currentTab, setCurrentTab] = useState(0);

  if (isEmpty(layout.tabs) || !visible) {
    return null;
  }

  return (
    <FieldWrapper
      label={schema.title || layout.options?.title}
      uiSchema={uischema}
    >
      <Tabs onChange={(_ev, tab) => setCurrentTab(tab)} value={currentTab}>
        {layout.tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.title || `#${1 + index}`}
            icon={tab.icon ? Icons[tab.icon] : undefined}
          />
        ))}
      </Tabs>
      <Box mt={3}>
        {renderLayoutElements(
          layout.tabs[currentTab].elements as UISchemaElement[],
          schema,
          path,
          enabled,
          renderers,
          cells
        )}
      </Box>
    </FieldWrapper>
  );
};

const tester: RankedTester = rankWith(20, (uiSchema) => {
  return isLayoutType(uiSchema, "TabsLayout");
});

export default {
  tester,
  renderer: withJsonFormsLayoutProps(TabsLayoutRenderer),
};
