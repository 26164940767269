import { createContext, useCallback, useEffect, useState, type ReactNode } from "react";

import type { LibraryItemDto, LibraryCollectionDto } from "@shared/api-client";

import { useAppContext, useBackend, useQuickMemo, useTranslation } from "@app/hooks";

import type { CreateCollectionData, CreateCollectionItemFormData } from "./types";

interface ItemFilters {
  category?: string[];
}

interface LibraryContextState {
  collections: LibraryCollectionDto[];
  selectedCollection: LibraryCollectionDto | undefined;
  selectCollection: (uuid: string) => Promise<void>;
  getCategories: () => Promise<string[]>;
  getCategoriesByCollectionUuid: (uuid: string) => Promise<string[]>;
  getCollectionByUuid: (uuid: string) => Promise<LibraryCollectionDto | undefined>;
  getCollectionItems: (filter?: ItemFilters) => Promise<LibraryItemDto[]>;
  getCollectionItemsByCollectionUuid: (uuid: string, filters?: ItemFilters) => Promise<LibraryItemDto[]>;
  addToCollection: (collectionUuid: string, data: CreateCollectionItemFormData) => Promise<void>;
  updateSelectedCollection: (data: CreateCollectionData) => Promise<void>;
  updateCollectionItem: (uuid: string, data: Partial<CreateCollectionItemFormData>) => Promise<void>;
  deleteCollectionItem: (uuid: string) => Promise<void>;
  doRefresh: () => Promise<void>;
}

export const LibraryContext = createContext<LibraryContextState | undefined>(undefined);

interface LibraryContextProviderProps {
  children: ReactNode;
}

export const LibraryContextProvider: React.FC<LibraryContextProviderProps> = ({ children }) => {
  const { notify } = useAppContext();
  const { library } = useBackend();
  const { t } = useTranslation();

  const [collections, setCollections] = useState<LibraryCollectionDto[]>([]);
  const [selectedCollection, setSelectedCollection] = useState<LibraryCollectionDto | undefined>();
  const [refresh, setRefresh] = useState(0);

  /** Effects **/

  useEffect(() => {
    // Fetch collections
    try {
      library.getList({ path: "/" }).then((response) => {
        setCollections(response.items);
      });
    } catch (error) {
      notify(t("library.errorFetchingCollections"), "error");
    }
  }, [library, notify, t, refresh]);

  /** Callbacks  **/

  const getCategoriesByCollectionUuid = useCallback(
    async (uuid: string) => {
      try {
        const { items } = await library.getList({
          path: "/:uuid/categories",
          params: { uuid }
        });
        return items;
      } catch (error) {
        notify(t("library.errorFetchingCategories"), "error");
        return [];
      }
    },
    [library, notify, t]
  );

  const getCategories = useCallback(async () => {
    if (!selectedCollection) {
      return [];
    }

    return getCategoriesByCollectionUuid(selectedCollection.uuid);
  }, [getCategoriesByCollectionUuid, selectedCollection]);

  const getCollectionItemsByCollectionUuid = useCallback(
    async (uuid: string, filters: ItemFilters = {}) => {
      try {
        const { items } = await library.getList({
          path: "/:uuid/items",
          params: { uuid },
          search: {
            ...filters,
            limit: 0 /* all items for now */
          }
        });
        return items;
      } catch (error) {
        notify(t("library.errorFetchingCollection"), "error");
        return [];
      }
    },
    [library, notify, t]
  );

  const getCollectionByUuid = useCallback(
    async (uuid: string) => {
      try {
        return await library.get({ path: "/:uuid", params: { uuid } });
      } catch (error) {
        notify(t("library.errorFetchingCollection"), "error");
        return undefined;
      }
    },
    [library, notify, t]
  );

  const selectCollection = useCallback(
    async (uuid: string) => {
      try {
        const collection = await getCollectionByUuid(uuid);
        setSelectedCollection(collection);
      } catch (error) {
        notify(t("library.errorFetchingCollection"), "error");
      }
    },
    [getCollectionByUuid, notify, t]
  );

  const getCollectionItems = useCallback(
    async (filters?: ItemFilters) => {
      if (!selectedCollection) {
        return [];
      }

      return getCollectionItemsByCollectionUuid(selectedCollection.uuid, filters);
    },
    [getCollectionItemsByCollectionUuid, selectedCollection]
  );

  const addToCollection = useCallback(
    async (collectionUuid: string, data: CreateCollectionItemFormData) => {
      try {
        await library.post({ path: "/:uuid/items", params: { uuid: collectionUuid } }, data);
        notify(t("library.addedToLibrary", { name: selectedCollection?.name }), "success");
      } catch (error) {
        notify(t("library.errorAddToLibrary"), "error");
        throw error;
      }
    },
    [library, notify, selectedCollection?.name, t]
  );

  const updateSelectedCollection = useCallback(
    async (data: CreateCollectionData) => {
      if (!selectedCollection) {
        return;
      }

      try {
        await library.patch({ path: "/:uuid", params: { uuid: selectedCollection.uuid } }, data);
        notify(t("library.updatedCollection"), "success");
      } catch (error) {
        notify(t("library.errorUpdateCollection"), "error");
        throw error;
      }
    },
    [library, notify, selectedCollection, t]
  );

  const updateCollectionItem = useCallback(
    async (uuid: string, data: Partial<CreateCollectionItemFormData>) => {
      if (!selectedCollection) {
        return;
      }

      try {
        await library.patch(
          { path: "/:uuid/items/:itemuuid", params: { uuid: selectedCollection.uuid, itemuuid: uuid } },
          data
        );
        notify(t("library.updatedCollectionItem"), "success");
      } catch (error) {
        notify(t("library.errorUpdateCollectionItem"), "error");
        throw error;
      }
    },
    [library, notify, selectedCollection, t]
  );

  const deleteCollectionItem = useCallback(
    async (uuid: string) => {
      if (!selectedCollection) {
        return;
      }

      try {
        await library.delete({
          path: "/:uuid/items/:itemuuid",
          params: { uuid: selectedCollection.uuid, itemuuid: uuid }
        });

        notify(t("library.deletedCollectionItem"), "success");
      } catch (error) {
        notify(t("library.errorDeleteCollectionItem"), "error");
        throw error;
      }
    },
    [library, notify, selectedCollection, t]
  );

  const doRefresh = useCallback(async () => {
    if (!selectedCollection) {
      return;
    }

    // Reload selected collection
    if (selectedCollection) {
      await selectCollection(selectedCollection.uuid);
    }

    // Reload collections
    setRefresh((prev) => prev + 1);
  }, [selectedCollection, selectCollection]);

  const contextValue = useQuickMemo<LibraryContextState>({
    collections,
    selectedCollection,
    selectCollection,
    getCollectionByUuid,
    getCategories,
    getCategoriesByCollectionUuid,
    getCollectionItems,
    getCollectionItemsByCollectionUuid,
    addToCollection,
    updateSelectedCollection,
    updateCollectionItem,
    deleteCollectionItem,
    doRefresh
  });

  return <LibraryContext.Provider value={contextValue}>{children}</LibraryContext.Provider>;
};
