import { useCallback, useEffect, useState } from "react";
import { Outlet, useMatches } from "react-router-dom";
import { Box, Chip, darken, styled } from "@mui/material";

import { type GameDefinitionDto, ApiRestError } from "@shared/api-client";

import { useAppContext, useTranslation } from "@app/hooks";
import { Link, useModals, useNavigate, useParams } from "@app/router";
import { EditorContextProvider, type EditorState } from "@app/editor";
import { getDrawerEditorMenuItems } from "@app/config";
import { Button } from "@app/components";
import { GameStatus } from "@app/types";
import { LibraryContextProvider } from "@app/library";

import PublicIcon from "@mui/icons-material/Public";
import LockIcon from "@mui/icons-material/Lock";
import PencilIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import PublishIcon from "@mui/icons-material/Publish";
import ShareIcon from "@mui/icons-material/Share";
import BoltIcon from "@mui/icons-material/Bolt";

const FancyButton = styled(Button)(({ theme }) => ({
  boxShadow: "0 0 10px rgba(0,0,0,.4)",
  background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${darken(theme.palette.primary.main, 0.5)}) !important`
}));

export default function ProjectEditLayout() {
  const { uuid } = useParams("/dashboard/projects/:uuid");
  const modals = useModals();
  const { setPageTitle, setHeaderContent, pushMenu, popMenu, setDrawerMode, notify, superPowers } = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matchedRoutes = useMatches();

  const [gameDefinition, setGameDefinition] = useState<GameDefinitionDto | undefined>();
  const [gameVersion, setGameVersion] = useState<string | undefined>();
  const [gameLevel, setGameLevel] = useState<string | undefined>();

  const doPublish = useCallback(
    (version: string) => {
      modals.open("/dashboard/projects/[uuid]/v/[version]/publish", {
        params: { uuid, version }
      });
    },
    [modals, uuid]
  );

  const handleError = useCallback(
    (error: unknown) => {
      // TODO: Any fancy way to handle this?
      if (error instanceof ApiRestError && error.status) {
        if (error.status >= 500) {
          notify(error.message, "error");
          throw error;
        }

        if (error.status === 404) {
          notify(t("common.notFound"), "warning");
          navigate("/dashboard/projects", { replace: true });
          return;
        }

        if ([403, 401].includes(error.status)) {
          notify(t("common.loggedOut"), "warning");
          navigate("/auth/login", { replace: true });
          return;
        }
      }

      notify(String(error), "error");
      throw error;
    },
    [navigate, notify, t]
  );

  const handleLoad = useCallback((gameDefinition: GameDefinitionDto, version?: string) => {
    setGameDefinition(gameDefinition);
    setGameVersion(version);
  }, []);

  const handleEditorUpdates = useCallback((editor: EditorState) => {
    setGameLevel(editor.level);
  }, []);

  // Handle header updates
  // Game title
  useEffect(() => {
    if (!gameDefinition) {
      return;
    }

    const isArchived = gameVersion && gameDefinition.currentVersion > Number(gameVersion);
    const isCurrent = gameVersion && gameDefinition.currentVersion === Number(gameVersion);
    const isPublic = gameDefinition.status === GameStatus.PUBLISHED;
    const isPrivate = gameDefinition.status === GameStatus.DRAFT;

    let variant: "outlined" | "filled" = "outlined";
    let icon = <PencilIcon />;
    let label: string;
    let color: "success" | "error" | "warning" | "info" | undefined = undefined;

    switch (true) {
      case isArchived:
        icon = <ArchiveIcon />;
        label = "archived";
        break;
      case isPublic && isCurrent:
        variant = "filled";
        label = "published";
        color = "success";
        icon = <PublicIcon />;
        break;
      case isPrivate && isCurrent:
        variant = "filled";
        label = "private";
        color = "warning";
        icon = <LockIcon />;
        break;
      default:
        label = "draft";
        break;
    }

    setPageTitle(t("editProject.title", { title: gameDefinition.name || t("projects.noTitle") }));

    // Preview/Publish buttons
    if (gameVersion) {
      setHeaderContent(
        <Box display='flex' gap={2} alignItems='center' justifyContent='space-between' ml={2} mr={2}>
          {gameDefinition.isTemplate && (
            <Chip
              label={t("projects.isTemplate")}
              icon={<BoltIcon />}
              variant='filled'
              color='primary'
              sx={{ ml: 2 }}
            />
          )}
          <Chip
            variant={variant}
            color={color}
            icon={icon}
            label={t(`fields.status.${label}`)}
            onClick={() => doPublish(gameVersion)}
          />
          <Box flex={1} />
          <Link
            to={{ pathname: "/preview/:uuid/:version", search: gameLevel ? `?level=${gameLevel}` : "" }}
            params={{ uuid, version: gameVersion }}
            target='theufolab-preview'
          >
            <Button variant='outlined' color='primary'>
              {t("editProject.preview")}
            </Button>
          </Link>
          <FancyButton
            variant='contained'
            startIcon={isPublic && isCurrent ? <ShareIcon /> : <PublishIcon />}
            onClick={() => doPublish(gameVersion)}
          >
            {t(isPublic && isCurrent ? "editProject.share" : "editProject.publish")}
          </FancyButton>
        </Box>
      );
    }
  }, [doPublish, gameDefinition, gameLevel, gameVersion, setHeaderContent, setPageTitle, t, uuid]);

  // Setup menu
  useEffect(() => {
    // Dynamic back button
    const onBack = () => {
      // Specific routes goes back to the editor/text
      const subRouteIds: RegExp[] = [
        /^dashboard\/projects\/\[uuid\]\/v\/\[version\]\/editor\/ui/,
        /^dashboard\/projects\/\[uuid\]\/v\/\[version\]\/settings\//
      ];

      if (matchedRoutes.some((route) => subRouteIds.some((regexp) => regexp.test(route.id)))) {
        if (gameVersion) {
          navigate("/dashboard/projects/:uuid/v/:version/editor/text", { params: { uuid, version: gameVersion } });
        } else {
          navigate("/dashboard/projects/:uuid", { params: { uuid } });
        }
        return;
      }

      navigate("/dashboard/projects");
    };

    pushMenu(1, getDrawerEditorMenuItems({ superPowers }, onBack), { uuid, version: gameVersion });
    return () => popMenu(1);
  }, [pushMenu, popMenu, navigate, uuid, gameVersion, matchedRoutes, superPowers]);

  // Collapse left drawer menu
  useEffect(() => {
    setDrawerMode("collapsed");
    return () => setDrawerMode("expanded");
  }, [setDrawerMode]);

  // cleanup on unmount
  useEffect(
    () => () => {
      setPageTitle();
      setHeaderContent();
    },
    [setHeaderContent, setPageTitle]
  );

  return (
    <LibraryContextProvider>
      <EditorContextProvider
        uuid={uuid}
        onLoad={handleLoad}
        onUpdateEditorState={handleEditorUpdates}
        onError={handleError}
      >
        <Outlet />
      </EditorContextProvider>
    </LibraryContextProvider>
  );
}
