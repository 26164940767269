import { gluePath } from "../paths";

export interface UrlSettings {
  assetBaseUrl: string;
  pluginBaseUrl: string;
  libraryBaseUrl: string;
  version?: string | number;
}

/**
 * Get the thumbnail URL.
 * - Replace the asset://, plugin:// or library:// prefix with the assetBaseUrl,
 *   pluginBaseUrl or libraryBaseUrl.
 * - Replace the {key} with the value from the obj (only works first level).
 *
 * @param uri
 * @param obj
 * @param settings
 * @returns
 */
export function getAssetUrl(
  uri: string,
  obj?: Record<string, unknown>,
  settings?: UrlSettings
): string {
  return uri
    .replace(/^([a-z]+):\/\/(.*)/, (_match, source, path) => {
      switch (source) {
        case "asset":
          return gluePath(settings?.assetBaseUrl, path);
        case "plugin":
          return gluePath(settings?.pluginBaseUrl, path);
        case "library":
          return gluePath(settings?.libraryBaseUrl, path);
        default:
          return _match;
      }
    })
    .replace(/\{([a-z0-9_\-.]+)\}/gi, (match: string, key: string) => {
      return obj ? getAssetUrl(String(obj[key]), obj, settings) : match;
    })
    .concat(settings?.version ? `?v=${settings.version}` : "");
}
