import { useEffect, useMemo, useRef, useState } from "react";
import Masonry from "react-responsive-masonry";
import { Box, CircularProgress, styled } from "@mui/material";

import { type LibraryItemDto } from "@shared/api-client";
import { type Asset } from "@shared/game-engine";

import { useBackend } from "@app/hooks";

import { LibraryItem } from "./LibraryItem";

import { SearchBox } from "@app/components/common/SearchBox";
import { Empty } from "@app/components/Empty";

const HorizontalList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  overflowX: "auto",
  overflowY: "hidden",
  padding: theme.spacing(2),

  "> *": {
    flex: "0 0 auto",
    height: 100,
    maxWidth: "min(75%, 180px)"
  }
}));

interface Props {
  libraryUuid: string;
  category?: string;
  components?: string[];
  uuids?: string[];
  onSelect?: (id: string, item: LibraryItemDto) => void;
  onUpload?: (files: File[]) => void;
  onDelete?: (asset: Asset, mustConfirm?: boolean) => void;
  allowSearch?: boolean;
  searchTerm?: string;
  disposition?: "horizontal" | "masonry";
}

export function LibraryPickerList(props: Props) {
  const { libraryUuid, components, allowSearch, category, disposition = "masonry", searchTerm = "" } = props;
  const { library } = useBackend();
  const containerRef = useRef<HTMLDivElement>(null);
  const [items, setItems] = useState<LibraryItemDto[] | null>(null);
  const [columns, setColumns] = useState(1);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (!allowSearch) {
      setSearch(searchTerm);
    }
  }, [allowSearch, searchTerm]);

  useEffect(() => {
    if (!items?.length) {
      return;
    }

    // listen width changes
    const observer = new ResizeObserver(() => {
      const width = containerRef.current?.clientWidth || 0;
      const newColumns = Math.floor(width / 120);
      setColumns(newColumns);
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [items?.length]);

  // Load library items
  useEffect(() => {
    const fetchItems = async () => {
      const response = await library.getList({
        path: "/:uuid/items",
        params: { uuid: libraryUuid },
        search: {
          component: components,
          category: category ? [category] : undefined,
          search
        }
      });

      setItems(response.items);
    };

    fetchItems();
  }, [category, components, library, libraryUuid, search]);

  const content = useMemo(() => items?.map((item) => <LibraryItem key={item.uuid} item={item} tooltip />), [items]);

  if (!items) {
    return (
      <Box display='flex' alignContent='center' justifyContent='center' p={10}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignContent='stretch'
      alignItems='stretch'
      height='100%'
      ref={containerRef}
    >
      {allowSearch && <SearchBox onSearch={setSearch} persistingKey='library-search' />}
      {disposition === "masonry" && items.length > 0 ? <Masonry columnsCount={columns}>{content}</Masonry> : null}
      {disposition === "horizontal" && items.length > 0 ? <HorizontalList>{content}</HorizontalList> : null}
      {items.length === 0 ? <Empty /> : null}
    </Box>
  );
}
