import { useState } from "react";
import { Alert, CircularProgress, Grid, Typography, useTheme } from "@mui/material";

import { type UserDto } from "@shared/api-client";

import { useBackend, useTranslation } from "@app/hooks";
import { LegacyForm, type FormErrors, TextField, ProfileWidget, Button } from "@app/components";

interface ProfileFormData extends Partial<UserDto> {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

export default function AccountProfilePage() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { loggedInUser, user: user, refresh } = useBackend();
  const [errors, setErrors] = useState<FormErrors<ProfileFormData>>({});

  const handleSubmit = async (data: ProfileFormData): Promise<boolean> => {
    setErrors({});

    if (data.currentPassword && !data.newPassword) {
      setErrors({ newPassword: t("account.profile.newPasswordMissing") });
      return false;
    }

    if (data.currentPassword && data.newPassword !== data.confirmPassword) {
      setErrors({ confirmPassword: t("account.profile.passwordMismatch") });
      return false;
    }

    try {
      await user.post("/", data);
      refresh();
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ root: error.message });
      } else {
        setErrors({ root: t("common.error").toString() });
      }
      return false;
    }

    return true;
  };

  if (!loggedInUser) {
    return <CircularProgress />;
  }

  // TODO: implement avatar upload
  const editableAvatar = false;
  const uploadNewAvatar = () => {
    // TODO: update avatar
  };

  return (
    <LegacyForm onSubmit={handleSubmit} initialData={loggedInUser} errors={errors}>
      {({ register, submitting, formState: { errors } }) => {
        const profileErrors = [errors.username, errors.email, errors.firstName, errors.lastName].filter(
          (error) => !!error
        );

        const passwordErrors = [errors.currentPassword, errors.newPassword, errors.confirmPassword].filter(
          (error) => !!error
        );

        return (
          <Grid container spacing={8}>
            <Grid item xs={12} sx={{ marginTop: theme.spacing(4) }} display='flex' justifyContent='start'>
              <ProfileWidget
                user={loggedInUser}
                uploadNewAvatar={editableAvatar ? uploadNewAvatar : undefined}
                size='large'
              />
            </Grid>

            <Grid item xs={12} md={6} display='flex' flexDirection='column' gap={theme.spacing(4)}>
              <Typography variant='h6'>{t("account.profile.basicInfo")}</Typography>

              <TextField label={t("account.profile.username")} inputProps={register("username")} fullWidth />

              <TextField
                label={t("account.profile.email")}
                inputProps={{ ...register("email"), readOnly: true }}
                fullWidth
              />

              <TextField label={t("account.profile.firstName")} inputProps={register("firstName")} fullWidth />

              <TextField label={t("account.profile.lastName")} inputProps={register("lastName")} fullWidth />

              {profileErrors.map((error, index) => (
                <Alert severity='error' key={index}>
                  {error?.message}
                </Alert>
              ))}
            </Grid>

            <Grid item xs={12} md={6} display='flex' flexDirection='column' gap={theme.spacing(4)}>
              <Typography variant='h6'>{t("account.profile.changePassword")}</Typography>

              <TextField
                label={t("account.profile.currentPassword")}
                fullWidth
                type='password'
                autoComplete='current-password'
                error={!!errors.currentPassword}
                aria-errormessage={errors.currentPassword?.message}
                inputProps={register("currentPassword")}
              />

              <TextField
                label={t("account.profile.newPassword")}
                fullWidth
                type='password'
                autoComplete='new-password'
                error={!!errors.newPassword}
                aria-errormessage={errors.newPassword?.message}
                inputProps={register("newPassword")}
              />

              <TextField
                label={t("account.profile.confirmPassword")}
                fullWidth
                type='password'
                autoComplete='new-password'
                error={!!errors.confirmPassword}
                aria-errormessage={errors.confirmPassword?.message}
                inputProps={register("confirmPassword")}
              />

              {passwordErrors.map((error, index) => (
                <Alert severity='error' key={index}>
                  {error?.message}
                </Alert>
              ))}
            </Grid>

            {errors.root && (
              <Grid item xs={12}>
                <Alert severity='error'>{errors.root.message}</Alert>
              </Grid>
            )}

            <Grid item xs={12} textAlign='center'>
              <Button type='submit' variant='contained' color='primary' size='large' loading={submitting}>
                {t("account.profile.save")}
              </Button>
            </Grid>
          </Grid>
        );
      }}
    </LegacyForm>
  );
}
