import { useRef } from "react";

/**
 * Quick memoization hook for objects. Dependences are set to all keys of the object.
 *
 * @param obj
 * @param id
 * @returns
 */
export function useQuickMemo<T extends object>(obj: T, id?: string): T {
  const memo = useRef<T>(obj);

  const keys = Object.keys(obj) as (keyof T)[];
  const changes: string[] = [];

  for (const key of keys) {
    if (memo.current[key] !== obj[key]) {
      memo.current = obj;
      changes.push(String(key));
      break; // Only need to find one change
    }
  }

  if (window.__debug__ && changes.length) {
    console.debug(`[useQuickMemo]${id ? ` [${id}]` : ""} Changes: ${changes.join(", ")}`);
  }

  return memo.current;
}
