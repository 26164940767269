import { useDropzone } from "react-dropzone";

import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlProps, RankedTester, rankWith } from "@jsonforms/core";
import { FieldWrapper } from "../common/FieldWrapper";
import { useEffect, useState, useCallback, useMemo } from "react";
import { isFileUploadControl } from "../utils";
import type { JsonSchema, JsonUISchema } from "../types";
import { Icons } from "../common/Icons";
import { DropZone } from "../common/DropZone";
import { IconButton, useTheme } from "@mui/material";

const stopPropagation = (e: React.MouseEvent | React.TouchEvent) => {
  e.stopPropagation();
};

interface Props extends ControlProps {
  data: string;
  path: string;
  handleChange: (path: string, value: string) => void;
}

const handleFileDrop = async (
  files: File[],
  onLoad: (reader: FileReader) => void
) => {
  const file = files[0];
  const reader = new FileReader();
  reader.onload = () => onLoad(reader);
  reader.readAsDataURL(file);
};

export function FileUploadFieldRenderer({
  label,
  description,
  data,
  path,
  visible,
  uischema,
  handleChange,
  required,
}: Props) {
  const theme = useTheme();
  const mimeType = uischema.options?.accept || "image/*";

  const [currentValue, setCurrentValue] = useState<string>("");

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) =>
      handleFileDrop(files, (reader) => {
        handleChange(path, reader.result?.toString() || "");
      }),
    accept: mimeType,
  });

  useEffect(() => {
    setCurrentValue(data !== undefined ? data : "");
  }, [data]);

  const handleRemoveImage = useCallback(
    (
      e:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      e.stopPropagation();
      handleChange(path, "");
    },
    [handleChange, path]
  );

  const iconButtonStyles = useMemo(
    () => ({
      position: "absolute",
      top: "5px",
      right: "5px",
      margin: theme.spacing(1, 0),
      background: theme.palette.grey[300],
      "&:hover": {
        background: theme.palette.grey[400],
      },
    }),
    [theme]
  );

  if (!visible) {
    return null;
  }

  const classes: string[] = [
    isDragActive ? "dropping" : "",
    currentValue ? "hasImage" : "",
  ].filter(Boolean);

  const isImage = mimeType.startsWith("image/");
  const isAudio = mimeType.startsWith("audio/");
  const isVideo = mimeType.startsWith("video/");
  const isOther = !isImage && !isAudio && !isVideo;

  return (
    <FieldWrapper label={label} description={description} uiSchema={uischema}>
      <DropZone
        className={classes.join(" ")}
        {...getRootProps()}
        style={{
          width: uischema.options?.width || "100%",
          height: uischema.options?.height || undefined,
        }}
      >
        {currentValue && (
          <>
            {isImage && <img src={currentValue} />}
            {isAudio && (
              <audio src={currentValue} controls onClick={stopPropagation} />
            )}
            {isVideo && (
              <video src={currentValue} controls onClick={stopPropagation} />
            )}
            {isOther && Icons.file}
            {!required && (
              <IconButton
                size="small"
                onClick={handleRemoveImage}
                sx={iconButtonStyles}
              >
                {Icons.trash}
              </IconButton>
            )}
          </>
        )}
        {!currentValue && (
          <>
            {Icons.photo}
            <p>Arrastra y suelta o haz click para buscar</p>
          </>
        )}
      </DropZone>
      <input {...getInputProps()} />
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(20, (uischema, schema, context) =>
  isFileUploadControl(uischema as JsonUISchema, schema as JsonSchema, context)
);

export default {
  tester,
  renderer: withJsonFormsControlProps(FileUploadFieldRenderer),
};
