import { useCallback } from "react";
import TagManager from "react-gtm-module";

import { createLogger } from "@shared/utils/logging";

import { useBackend } from "./useBackend";

type ProtectedKeys = "event";

const { info: log } = createLogger("TagManager", { canLog: () => window.__debug__ });

export function useTagManager() {
  const { loggedInUser } = useBackend();

  const tm = useCallback(
    (
      event: string,
      data: Record<string, unknown> & {
        [K in ProtectedKeys]?: never;
      } = {}
    ) => {
      log("DataLayer", data);

      TagManager.dataLayer({
        dataLayer: {
          ...data,
          event,
          user_id: loggedInUser?.id
        }
      });
    },
    [loggedInUser]
  );

  return tm;
}
