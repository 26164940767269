import { type CSSProperties } from "react";
import {
  GamePluginType,
  type PluginComponentBaseProps,
} from "@shared/utils/plugins";
import { GameElementCategory } from "@shared/game-engine";

import { createPluginComponent } from "../utils/createPluginComponent";

const PLACEHOLDER_STYLES: CSSProperties = {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "transparent",
  textAlign: "center",
};

export const PlaceholderComponent = createPluginComponent<
  PluginComponentBaseProps<void>
>(
  () => {
    return <div style={PLACEHOLDER_STYLES}></div>;
  },
  {
    type: GamePluginType.GAME_COMPONENT,
    category: GameElementCategory.STATIC,
    name: "Placeholder",
    description: "A placeholder component",
    author: "none",
    version: 0,
    thumbnail: "",
    assets: [],
    schema: {
      type: "object",
    },
    features: {},
    templates: [],
  }
);
