import { useCallback, useEffect } from "react";

import { useAppContext, useTranslation } from "@app/hooks";

import { PageLoading } from "@app/components";
import { useNavigate } from "@app/router";
import { type LibraryCollectionDto } from "@shared/api-client";

import { CollectionList } from "@app/components/library/CollectionList";
import { useLibrary } from "@app/library";

export default function LibraryPage() {
  const { setPageTitle } = useAppContext();
  const { t } = useTranslation();
  const { collections } = useLibrary();
  const navigate = useNavigate();

  const navigateToCollection = useCallback(
    (collection?: LibraryCollectionDto) => {
      if (!collection) {
        return;
      }

      navigate("/dashboard/my-library/:uuid", {
        params: { uuid: collection.uuid }
      });
    },
    [navigate]
  );

  useEffect(() => {
    setPageTitle(t("library.title"));
    return () => setPageTitle("");
  }, [setPageTitle, t]);

  if (!collections) {
    return <PageLoading />;
  }

  return (
    <>
      <CollectionList collections={collections} onSelect={navigateToCollection} />
    </>
  );
}
