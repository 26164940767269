import { useCallback, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Container, Tab, Tabs } from "@mui/material";

import { useAppContext, useTranslation } from "@app/hooks";
import { type Path, useNavigate, useParams } from "@app/router";

import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import InventoryIcon from "@mui/icons-material/Inventory";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { PageLoading } from "@app/components";
import { useLibrary } from "@app/library";

export default function MyLibraryLayout() {
  const { uuid } = useParams("/dashboard/my-library/:uuid");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentMatchedRoute, setPageTitle } = useAppContext();
  const { selectCollection, selectedCollection } = useLibrary();

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, value: Path) => {
      navigate(value, { params: { uuid } });
    },
    [navigate, uuid]
  );

  useEffect(() => {
    selectCollection(uuid);
  }, [uuid, selectCollection]);

  useEffect(() => {
    if (!selectedCollection) {
      return;
    }

    setPageTitle(t("library.collectionTitle", { name: selectedCollection.name }));
    return () => setPageTitle("");
  }, [selectCollection, selectedCollection, setPageTitle, t]);

  if (!uuid) {
    return <PageLoading />;
  }

  return (
    <Container>
      <Tabs centered onChange={handleTabChange} value={currentMatchedRoute}>
        <Tab
          label={t("library.menu.back")}
          icon={<ArrowBackIcon />}
          iconPosition='start'
          value='/dashboard/my-library'
          aria-controls='library-tab-back'
        />
        <Tab
          label={t("library.menu.content")}
          icon={<InventoryIcon />}
          iconPosition='start'
          value='/dashboard/my-library/:uuid/content'
          aria-controls='library-tab-content'
        />
        <Tab
          label={t("library.menu.settings")}
          icon={<SettingsApplicationsIcon />}
          iconPosition='start'
          value='/dashboard/my-library/:uuid/settings'
          aria-controls='library-tab-settings'
        />
      </Tabs>

      <Outlet />
    </Container>
  );
}
