import { ApiRestClient } from "../ApiRest";
import { LibraryCollectionDto, LibraryItemDto } from "../dtos";

type Routes = {
  "/": LibraryCollectionDto;
  "/:uuid": LibraryCollectionDto;
  "/:uuid/items": LibraryItemDto;
  "/:uuid/items/:itemuuid": LibraryItemDto;
  "/:uuid/categories": string;
};

export class LibraryApiClient extends ApiRestClient<Routes> {
  constructor(baseUrl: string) {
    super(baseUrl.replace(/\/$/, "") + "/library");
  }
}
