import { usePersistedState, useTranslation } from "@app/hooks";
import { useCallback, useEffect, useMemo } from "react";
import { Box, IconButton, styled } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { TextField } from "@app/components";

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "stretch",
  mb: 1
});

interface Props {
  persistingKey?: string;
  onSearch: (value: string) => void;
  debounceTime?: number;
}

export function SearchBox(props: Props) {
  const { persistingKey, onSearch, debounceTime = 300 } = props;
  const { ts } = useTranslation();

  const [search, setSearch] = usePersistedState<string>(
    persistingKey || "none",
    "",
    persistingKey ? "session" : "none"
  );

  const handleClearSearch = useCallback(() => {
    setSearch("");
  }, [setSearch]);

  const inputProps = useMemo(
    () => ({
      endAdornment: (
        <IconButton onClick={handleClearSearch} size='small'>
          {<ClearIcon />}
        </IconButton>
      )
    }),
    [handleClearSearch]
  );

  useEffect(() => {
    if (!search.trim()) {
      // Cleanup not debounced
      onSearch("");
    }

    const timeout = setTimeout(() => {
      onSearch(search.trim());
    }, debounceTime);

    return () => clearTimeout(timeout);
  }, [debounceTime, onSearch, search]);

  return (
    <StyledBox>
      <TextField
        variant='outlined'
        size='small'
        placeholder={ts("common.search")}
        value={search}
        inputProps={inputProps}
        onChange={useCallback(
          (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value),
          [setSearch]
        )}
      />
    </StyledBox>
  );
}
