import { Grid } from "@mui/material";

import { type LibraryCollectionDto } from "@shared/api-client";

import { CollectionCard } from "./CollectionCard";

interface Props {
  collections: LibraryCollectionDto[];
  onSelect?: (collection?: LibraryCollectionDto) => void;
}

export function CollectionItem({ collection, onClick }: { collection: LibraryCollectionDto; onClick: () => void }) {
  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <CollectionCard collection={collection} onClick={onClick} />
    </Grid>
  );
}

export function CollectionList({ collections, onSelect }: Props) {
  // const { t } = useTranslation();
  // const handleCreateCollection = useCallback(() => {
  //   onSelect?.({
  //     uuid: "",
  //     name: t("library.blankCollection").toString(),
  //     description: t("library.blankCollectionDescription").toString(),
  //     accessLevel: "private",
  //     createdAt: new Date(),
  //     updatedAt: new Date()
  //   });
  // }, [onSelect, t]);

  return (
    <>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <BlankCard onClick={handleCreateCollection} />
        </Grid> */}
        {collections.map((collection) => (
          <CollectionItem key={collection.uuid} collection={collection} onClick={() => onSelect?.(collection)} />
        ))}
      </Grid>
    </>
  );
}
