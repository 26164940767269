import { useMemo } from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlProps, RankedTester, rankWith } from "@jsonforms/core";
import { Alert, Box, styled } from "@mui/material";

import { FieldWrapper } from "../common/FieldWrapper";
import { isCustomControl } from "../utils";
import { GameElement } from "@shared/game-engine";

const CANVAS_WIDTH = 500;
const CANVAS_HEIGHT = 500;

const MiniCanvas = styled(Box)(({ theme }) => ({
  width: `${CANVAS_WIDTH}px`,
  height: `${CANVAS_HEIGHT}px`,
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "8px",
  zoom: 0.5,
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",

  // checkboard data image
  background:
    "url('data:image/svg+xml;base64,DQo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIgMiI+DQogICAgPGc+DQogICAgCTxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNmZmZmZmYiLz4NCiAgICAJPHJlY3QgeD0iMSIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0iI2VlZWVlZSIvPg0KICAgIAk8cmVjdCB4PSIwIiB5PSIxIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSIjZWVlZWVlIi8+DQogICAgCTxyZWN0IHg9IjEiIHk9IjEiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNmZmZmZmYiLz4NCiAgICA8L2c+DQo8L3N2Zz4NCg==')",
}));

interface Props extends ControlProps {
  data: GameElement["properties"];
  path: string;
  handleChange: (path: string, value: string) => void;
}

export function ComponentPreviewRenderer({
  data,
  label,
  description,
  visible,
  uischema,
  config,
}: Props) {
  const Component = config.context?.component;
  const editorContext = config.context?.editorContext;
  const options = uischema.options || {};

  const renderedComponent = useMemo(() => {
    if (!Component || !editorContext?.renderer?.renderElement) {
      return <Alert severity="warning">Previsualzación no disponible</Alert>;
    }

    const renderedData = editorContext?.renderer?.renderElement({
      id: "dummy",
      type: "game-component",
      component: config.context?.manifest?.id,
      stage: {
        width: options.width || CANVAS_WIDTH,
        height: options.height || CANVAS_HEIGHT,
        scale: options.scale || 0.5,
        x: 0,
        y: 0,
        absoluteScale: options.scale || 0.5,
        viewportScale: options.scale || 0.5,
      },
      properties: { ...data, ...options.properties },
    });

    return Component ? <Component {...renderedData} __preview__ /> : null;
  }, [
    Component,
    config.context?.manifest?.id,
    data,
    editorContext?.renderer,
    options.height,
    options.properties,
    options.scale,
    options.width,
  ]);

  if (!visible) {
    return null;
  }

  return (
    <FieldWrapper label={label} description={description} uiSchema={uischema}>
      <MiniCanvas sx={{ width: options.width, height: options.height }}>
        {renderedComponent}
      </MiniCanvas>
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(15, (uiSchema) => {
  return isCustomControl(uiSchema, "ComponentPreview");
});

export default {
  tester,
  renderer: withJsonFormsControlProps(ComponentPreviewRenderer),
};
