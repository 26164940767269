import { DashboardApiClient, PlayerApiClient } from "./apis";
import { AuthApiClient } from "./apis/AuthApiClient";
import { GameManagerApiClient } from "./apis/GameManagerApiClient";
import { PluginManagerApiClient } from "./apis/PluginManagerApiClient";
import { UserApiClient } from "./apis/UserApiClient";
import { LibraryApiClient } from "./apis/LibraryApiClient";

/**
 * API client for the backend.
 *
 * This class is a wrapper around the various API clients for the different parts of the backend.
 */
export class ApiClient {
  public readonly auth: AuthApiClient;
  public readonly dashboard: DashboardApiClient;
  public readonly gameManager: GameManagerApiClient;
  public readonly player: PlayerApiClient;
  public readonly pluginManager: PluginManagerApiClient;
  public readonly library: LibraryApiClient;
  public readonly user: UserApiClient;

  /**
   * Create a new API client.
   *
   * @param apiBaseUrl The base URL of the backend.
   */
  constructor(apiBaseUrl: string, publicApiBaseUrl: string) {
    this.auth = new AuthApiClient(apiBaseUrl);
    this.dashboard = new DashboardApiClient(apiBaseUrl);
    this.gameManager = new GameManagerApiClient(apiBaseUrl);
    this.player = new PlayerApiClient(publicApiBaseUrl);
    this.pluginManager = new PluginManagerApiClient(apiBaseUrl);
    this.library = new LibraryApiClient(apiBaseUrl);
    this.user = new UserApiClient(apiBaseUrl);
  }

  /**
   * Set the token to be used for authentication.
   *
   * @param token
   */
  setToken(token?: string): void {
    this.dashboard.setToken(token || null);
    this.gameManager.setToken(token || null);
    this.player.setToken(token || null);
    this.pluginManager.setToken(token || null);
    this.user.setToken(token || null);
    this.library.setToken(token || null);
  }
}

export * from "./ApiRest";
export * from "./ApiRestError";
export * from "./dtos";
export * from "./apis";
