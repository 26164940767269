import { type CSSProperties, useEffect, useMemo, useState } from "react";

import { type GameElement } from "@shared/game-engine";
import { getTransforms } from "@shared/game-player/utils/styles";
import { MDEditor } from "@shared/markdown/MDEditor";
import type { RenderingContext } from "@shared/game-player";

interface Props {
  gameElement: GameElement;
  language: string;
  textField?: string;
  renderingContext: RenderingContext;
  onSubmit?: (value: string) => void;
  onChange?: (value: string) => void;
  onCancel?: () => void;
}

export function StageMDEditor(props: Props) {
  const { textField = "text", language, gameElement, renderingContext, onSubmit, onCancel, onChange } = props;

  const [currentContent, setCurrentContent] = useState<string>("");
  const [editorRect, setEditorRect] = useState({ x: 0, y: 0, width: 0, height: 0 });

  const propValue = gameElement.translations?.[language]?.[textField];

  useEffect(() => {
    setCurrentContent(propValue || "");
  }, [propValue]);

  // Calculate the relative position of the text element
  useEffect(() => {
    // Find the text element
    const containerEl = document.getElementById(`el-${gameElement.id}`);
    const textEl = containerEl?.querySelector('[data-text-editable="text"]');

    if (!textEl || !containerEl) {
      setEditorRect({ x: 0, y: 0, width: 0, height: 0 });
      return;
    }

    const containerRect = containerEl.getBoundingClientRect();
    const textRect = textEl.getBoundingClientRect();
    const scale = renderingContext.canvasScale * gameElement.stage.scale;

    setEditorRect({
      x: (textRect.left - containerRect.left) / scale,
      y: (textRect.top - containerRect.top) / scale,
      width: textRect.width / scale,
      height: textRect.height / scale
    });
  }, [gameElement.id, gameElement.stage.scale, renderingContext.canvasScale]);

  const wrapperStyle: CSSProperties = {
    position: "absolute",
    zIndex: 1,
    boxSizing: "border-box",
    ...getTransforms(gameElement.stage)
  };

  const properties = gameElement.properties || {};

  // Includes support for legacy fontStyle property
  const isItalic =
    properties.italic !== undefined
      ? properties.italic
      : ["bold-italic", "italic"].includes(properties.fontStyle as string);
  const isBold =
    properties.bold !== undefined ? properties.bold : ["bold-italic", "bold"].includes(properties.fontStyle as string);

  // Assumed that the game element is a text element
  const editorStyle: CSSProperties = useMemo(
    () => ({
      position: "relative",
      top: editorRect.y,
      left: editorRect.x,
      width: editorRect.width,
      minHeight: editorRect.height,
      fontFamily: (properties.fontFamily as string) || "Inter",
      fontSize: (properties.fontSize as number) || 16,
      lineHeight: (properties.lineHeight as string) || "1em",
      fontStyle: isItalic ? "italic" : "normal",
      fontWeight: isBold ? "bold" : "normal",
      textDecoration: properties.underline ? "underline" : "none",
      color: (properties.color as string) || undefined,
      textAlign: (properties.textAlign as CSSProperties["textAlign"]) || "left",
      verticalAlign: (properties.verticalAlign as string) || "top",
      resize: "none",
      outline: "8px solid #ffd700"
    }),
    [
      isBold,
      isItalic,
      editorRect.height,
      editorRect.width,
      editorRect.x,
      editorRect.y,
      properties.color,
      properties.fontFamily,
      properties.fontSize,
      properties.lineHeight,
      properties.textAlign,
      properties.underline,
      properties.verticalAlign
    ]
  );

  return (
    <div
      style={wrapperStyle}
      // Prevent bubbling up so select & shortcut keys works
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <MDEditor
        content={currentContent}
        style={editorStyle}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onChange={(value) => {
          setCurrentContent(value);
          onChange?.(value);
        }}
      />
    </div>
  );
}
