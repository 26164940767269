import { alpha, createTheme } from "@mui/material";

import { colors } from "@shared/branding/colors";

export const defaultTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          color: colors.darkOrange,
          textDecoration: "none"
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: colors.transparent
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: colors.whiteBone
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: colors.black
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: colors.whiteBone
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.5)"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.darkPurple,
          ".MuiListItemIcon-root": {
            color: colors.darkPurple
          },
          "&:hover": {
            ".MuiListItemIcon-root": {
              color: colors.white
            }
          }
        }
      },
      defaultProps: {
        disableRipple: true
      }
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: "0.9em"
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          ".MuiMenuItem-root:hover": {
            color: colors.whiteBone
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          ".MuiGrid-grid-xs-10": {
            paddingLeft: 10 // Hack to fix the padding of the accordion summary
          }
        }
      }
    }
  },
  shape: {
    borderRadius: 8
  },
  spacing: 4,
  typography: {
    fontFamily: "TheUFOLabFont, Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
    fontSize: 14,
    body2: {
      fontSize: "1.2em"
    }
  },
  palette: {
    primary: {
      main: colors.purple,
      dark: colors.deepPurple,
      contrastText: colors.whiteBone
    },
    secondary: {
      main: colors.orange,
      contrastText: colors.deepPurple
    },
    error: {
      main: colors.red,
      contrastText: colors.whiteBone
    },
    warning: {
      main: colors.orange,
      contrastText: colors.whiteBone
    },
    info: {
      main: colors.brightGray,
      contrastText: colors.darkGray
    },
    background: {
      default: colors.transparent,
      paper: colors.white
    },
    text: {
      primary: colors.gray,
      secondary: colors.lightGray,
      disabled: colors.brightGray
    },
    action: {
      active: colors.purple,
      hover: alpha(colors.purple, 0.5),
      selected: colors.orange,
      disabled: colors.lightGray,
      disabledBackground: colors.brightGray
    }
  }
});
