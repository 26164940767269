/**
 * Glue paths together. Ignores double slashes. Keep protocol double slashes.
 *
 * @example
 * ```ts
 * gluePath("https://example.com", "api", "v1", "users") // "https://example.com/api/v1/users"
 * gluePath("https://example.com/", "/api/", "/v1/", "/users/") // "https://example.com/api/v1/users"
 * ```
 *
 * @param parts
 * @returns
 */
export function gluePath(
  ...parts: (string | number | undefined | null)[]
): string {
  return parts
    .map((part) => (part ? String(part).replace(/^\/|\/$/g, "") : ""))
    .filter((part) => part.length > 0)
    .join("/");
}
