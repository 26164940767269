import { Box, Container, Typography, useTheme } from "@mui/material";
import { useLocation, Link } from "react-router-dom";

import { useTranslation } from "@app/hooks";
import { Button } from "@app/components";

import UfitoImg from "@shared/branding/imagery/art/ufito-dubitativo-2.png";

export default function OfflinePage() {
  const { t } = useTranslation();
  const theme = useTheme();

  // redirectTo query param
  const location = useLocation();
  const targetUrl = new URLSearchParams(location.search).get("redirectTo") || "/";

  return (
    <Container>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        sx={{ padding: theme.spacing(5), height: "100vh" }}
      >
        <img src={UfitoImg} height='200' style={{ marginLeft: "60px" }} />
        <Typography textAlign='center' display='block' variant='h3' sx={{ margin: theme.spacing(8, 0, 0, 0) }}>
          {t("offline.title")}
        </Typography>
        <Typography textAlign='center' display='block' variant='h4' sx={{ margin: theme.spacing(2, 0, 0, 0) }}>
          {t("offline.content")}
        </Typography>
        <Link to={targetUrl} replace>
          <Button variant='contained' sx={{ margin: theme.spacing(4, 0, 0, 0) }}>
            {t("offline.retry")}
          </Button>
        </Link>
      </Box>
    </Container>
  );
}
