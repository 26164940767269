import { type GetTranslationStringCb } from "@app/contexts";
import { AccessLevel } from "@app/types/enums";
import { type JsonUISchema, type JsonSchema } from "@shared/form-builder";

interface SchemaContext {
  ts: GetTranslationStringCb;
}

interface SchemaOptions {
  restrictAccessLevel?: boolean;
}

export const getLibraryCollectionSchema = ({ ts }: SchemaContext, options?: SchemaOptions): JsonSchema => ({
  type: "object",
  properties: {
    thumbnail: {
      type: "string",
      title: ts("fields.library.thumbnail"),
      format: "data-url"
    },
    name: {
      type: "string",
      title: ts("fields.library.name")
    },
    description: {
      type: "string",
      title: ts("fields.library.description")
    },
    accessLevel: {
      type: "string",
      title: ts("fields.library.accessLevel"),
      oneOf: Object.values(AccessLevel).map((level) => ({
        title: ts(`fields.accessLevel.${level}`),
        const: level
      })),
      default: AccessLevel.PRIVATE,
      readOnly: options?.restrictAccessLevel ? true : false
    }
  },
  required: ["name", "accessLevel"]
});

export const getLibraryCollectionUISchema = (): JsonUISchema => ({
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/thumbnail",
      options: {
        flatStyle: true
      }
    },
    {
      type: "Control",
      scope: "#/properties/name",
      options: {
        flatStyle: true
      }
    },
    {
      type: "Control",
      scope: "#/properties/description",
      options: {
        flatStyle: true,
        multi: true
      }
    },
    {
      type: "Control",
      scope: "#/properties/accessLevel",
      options: {
        flatStyle: true
      }
    }
  ]
});
