import { Alert, InputAdornment, useTheme } from "@mui/material";

import { useTranslation } from "@app/hooks";
import { Button, TextField, type FormErrors, LegacyForm } from "@app/components";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

export interface PasswordRecoveryFormData {
  email: string;
}

interface PasswordRecoveryFormProps {
  errors?: FormErrors<PasswordRecoveryFormData>;
  onSubmit: (data: PasswordRecoveryFormData) => Promise<boolean>;
}

export const PasswordRecoveryForm = (props: PasswordRecoveryFormProps) => {
  const { onSubmit, errors } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <LegacyForm onSubmit={onSubmit} errors={errors}>
      {({ register, submitting, formState: { errors, isValid } }) => (
        <>
          <TextField
            type='email'
            variant='outlined'
            margin='normal'
            required
            id='passwordRecovery-email'
            aria-label={t("passwordRecovery.email").toString()}
            placeholder={t("passwordRecovery.email").toString()}
            autoComplete='email'
            autoFocus
            error={!!errors.email}
            aria-errormessage={errors.email?.message}
            inputProps={{
              ...register("email", { required: true, pattern: /^\S+@\S+\.\S{2,}$/i }),
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailOutlinedIcon color='disabled' />
                </InputAdornment>
              )
            }}
          />

          {errors.root && (
            <Alert sx={{ marginTop: theme.spacing(1) }} severity='error'>
              {errors.root.message}
            </Alert>
          )}

          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            sx={{ marginTop: theme.spacing(4) }}
            loading={submitting}
            disabled={!isValid}
          >
            {t("passwordRecovery.submit")}
          </Button>
        </>
      )}
    </LegacyForm>
  );
};
