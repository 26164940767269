import { useState, useEffect, useCallback } from "react";
import { CircularProgress } from "@mui/material";

import { type GamePluginDto } from "@shared/api-client";
import { ComponentPickerGroup, ComponentPickerList } from "@app/editor";
import { Button } from "@app/components";
import { useTranslation } from "@app/hooks";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { type Asset } from "@shared/game-engine";

import type { ComponentPickerItem } from "./types";

interface Props {
  components: GamePluginDto[];
  onSelect?: (id: string, data: ComponentPickerItem) => void;
  onUpload?: (files: File[]) => void;
  onDelete?: (asset: Asset, mustConfirm?: boolean) => void;
}

export function ComponentPickerNav(props: Props) {
  const { components, onSelect, onUpload, onDelete } = props;
  const [items, setItems] = useState<ComponentPickerItem[] | null>(null);
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState<ComponentPickerItem | null>(null);

  const handleSelect = useCallback(
    (id: string, item: ComponentPickerItem) => {
      setSelectedItem(item);
      onSelect?.(id, item);
    },
    [onSelect]
  );

  // Build the item list.
  useEffect(() => {
    const componentItems: ComponentPickerItem[] = components.map((component) => ({
      name: component.name,
      thumbnail: component.thumbnail,
      component
    }));

    setItems(componentItems);
  }, [components]);

  if (!items) {
    return <CircularProgress />;
  }

  if (selectedItem) {
    return (
      <>
        <Button startIcon={<ArrowBackIcon />} variant='contained' onClick={() => setSelectedItem(null)} sx={{ mb: 5 }}>
          {t("common.back")}
        </Button>
        <ComponentPickerGroup
          title={selectedItem.name}
          component={selectedItem.component}
          expanded
          onUpload={onUpload}
          onDelete={onDelete}
        />
        ;
      </>
    );
  }

  return <ComponentPickerList items={items} onSelect={handleSelect} />;
}
