import { useCallback } from "react";
import { Outlet } from "react-router-dom";
import { Container, Tab, Tabs } from "@mui/material";

import { useAppContext, useTranslation } from "@app/hooks";
import { type Path, useNavigate, useParams } from "@app/router";

import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import GamepadIcon from "@mui/icons-material/Gamepad";
import HistoryIcon from "@mui/icons-material/History";

export default function SettingsLayout() {
  const { uuid, version } = useParams("/dashboard/projects/:uuid/v/:version/settings");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentMatchedRoute, superPowers } = useAppContext();

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, value: Path) => {
      navigate(value, { params: { uuid, version } });
    },
    [navigate, uuid, version]
  );

  return (
    <Container>
      <Tabs centered onChange={handleTabChange} value={currentMatchedRoute}>
        <Tab
          label={t("editProject.general.menu")}
          icon={<SettingsApplicationsIcon />}
          iconPosition='start'
          value='/dashboard/projects/:uuid/v/:version/settings/general'
          aria-controls='settings-tab-general'
        />
        <Tab
          label={t("editProject.canvas.menu")}
          icon={<ColorLensIcon />}
          iconPosition='start'
          value='/dashboard/projects/:uuid/v/:version/settings/canvas'
          aria-controls='settings-tab-canvas'
        />
        {superPowers && (
          <Tab
            label={t("editProject.gamification.menu")}
            icon={<GamepadIcon />}
            iconPosition='start'
            value='/dashboard/projects/:uuid/v/:version/settings/gamification'
            aria-controls='settings-tab-gamification'
          />
        )}
        {superPowers && (
          <Tab
            label={t("editProject.revisions.menu")}
            icon={<HistoryIcon />}
            iconPosition='start'
            value='/dashboard/projects/:uuid/v/:version/settings/revisions'
            aria-controls='settings-tab-revisions'
          />
        )}
      </Tabs>

      <Outlet />
    </Container>
  );
}
