import { useState } from "react";
import { Alert, FormControlLabel, IconButton, InputAdornment, useTheme } from "@mui/material";

import { useTranslation } from "@app/hooks";
import { Button, TextField, Checkbox, LegacyForm, type FormErrors } from "@app/components";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

export interface RegistrationFormData {
  username: string;
  email: string;
  password: string;
  terms: boolean;
}

interface RegistrationFormProps {
  errors?: FormErrors<RegistrationFormData>;
  onSubmit: (data: RegistrationFormData) => Promise<boolean>;
  onGoogleRegistration?: () => Promise<boolean>;
}

export const RegistrationForm = (props: RegistrationFormProps) => {
  const { onSubmit, onGoogleRegistration, errors } = props;

  const theme = useTheme();
  const { t, tx } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <LegacyForm onSubmit={onSubmit} errors={errors}>
      {({ register, submitting, formState: { errors, isValid } }) => (
        <>
          <TextField
            type='text'
            variant='outlined'
            margin='normal'
            required
            id='registration-username'
            aria-label={t("registration.username").toString()}
            placeholder={t("registration.username").toString()}
            autoComplete='name'
            autoFocus
            error={!!errors.username}
            aria-errormessage={errors.username?.message}
            inputProps={{
              ...register("username", { required: true }),
              startAdornment: (
                <InputAdornment position='start'>
                  <PermIdentityIcon color='disabled' />
                </InputAdornment>
              )
            }}
          />
          <TextField
            type='email'
            variant='outlined'
            margin='normal'
            required
            id='registration-email'
            aria-label={t("registration.email").toString()}
            placeholder={t("registration.email").toString()}
            autoComplete='email'
            error={!!errors.email}
            aria-errormessage={errors.email?.message}
            inputProps={{
              ...register("email", { required: true, pattern: /^\S+@\S+\.\S{2,}$/i }),
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailOutlinedIcon color='disabled' />
                </InputAdornment>
              )
            }}
          />
          <TextField
            type={showPassword ? "text" : "password"}
            variant='outlined'
            margin='normal'
            required
            aria-label={t("registration.password").toString()}
            placeholder={t("registration.password").toString()}
            id='registration-password'
            autoComplete='new-password'
            error={!!errors.password}
            aria-errormessage={errors.password?.message}
            inputProps={{
              ...register("password", { required: true }),
              startAdornment: (
                <InputAdornment position='start'>
                  <LockOutlinedIcon color='disabled' />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton aria-label='Show password' onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityIcon color='disabled' /> : <VisibilityOffIcon color='disabled' />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                error={!!errors.terms}
                inputProps={register("terms", { validate: (value) => value === true })}
              />
            }
            label={tx("registration.terms")}
            sx={{ alignItems: "flex-start" }}
          />
          {errors.root && (
            <Alert sx={{ marginTop: theme.spacing(1) }} severity='error'>
              {errors.root.message || t(`forms.errors.${errors.root.type}`)}
            </Alert>
          )}

          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            sx={{ marginTop: theme.spacing(4) }}
            loading={submitting}
            disabled={!isValid}
          >
            {t("registration.submit")}
          </Button>
          {!!onGoogleRegistration && (
            <Button
              type='button'
              fullWidth
              variant='outlined'
              sx={{ marginTop: theme.spacing(1) }}
              disabled={submitting}
            >
              <GoogleIcon sx={{ marginRight: theme.spacing(1) }} />
              {t("registration.googleRegistration")}
            </Button>
          )}
        </>
      )}
    </LegacyForm>
  );
};
